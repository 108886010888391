import { useSimulationStore } from '@/components/calculator/services/SimulationStore';
import { computed, ref } from 'vue';
import { ReferenceRate } from '@/models/Demand';

export enum ReferenceRateMode {
  COMMON = 'COMMON',
  SPLIT = 'SPLIT',
}

export const useReferenceRate = () => {
  const simulationStore = useSimulationStore();

  const referenceRateState = ref<ReferenceRate>({
    referenceRate: simulationStore.userInput?.additionalRequests.referenceRate ?? null,
    referenceRateWibor3m: simulationStore.userInput?.additionalRequests.referenceRateWibor3m ?? null,
    referenceRateWibor6m: simulationStore.userInput?.additionalRequests.referenceRateWibor6m ?? null,
    referenceRateWibor1m: simulationStore.userInput?.additionalRequests.referenceRateWibor1m ?? null,
  });

  const isThreeOrSixMonthsReferenceRate = computed<boolean>(() =>
    Boolean(simulationStore.userInput?.additionalRequests.referenceRateWibor3m ||
      simulationStore.userInput?.additionalRequests.referenceRateWibor6m)
  );

  const selectedReferenceRateType = ref<ReferenceRateMode>(
    isThreeOrSixMonthsReferenceRate.value ? ReferenceRateMode.SPLIT : ReferenceRateMode.COMMON
  );

  const referenceRate = computed<Nullable<number>>({
    set: (value) => {
      simulationStore.userInput!.additionalRequests.referenceRate = value;
      referenceRateState.value.referenceRate = value;
    },
    get: () => referenceRateState.value.referenceRate,
  });

  const referenceRateWibor3m = computed<Nullable<number>>({
    set: (value) => {
      simulationStore.userInput!.additionalRequests.referenceRateWibor3m = value;
      referenceRateState.value.referenceRateWibor3m = value;
    },
    get: () => referenceRateState.value.referenceRateWibor3m,
  });

  const referenceRateWibor6m = computed<Nullable<number>>({
    set: (value) => {
      simulationStore.userInput!.additionalRequests.referenceRateWibor6m = value;
      referenceRateState.value.referenceRateWibor6m = value;
    },
    get: () => referenceRateState.value.referenceRateWibor6m,
  });

  const referenceRateWibor1m = computed<Nullable<number>>({
    set: (value) => {
      simulationStore.userInput!.additionalRequests.referenceRateWibor1m = value;
      referenceRateState.value.referenceRateWibor1m = value;
    },
    get: () => referenceRateState.value.referenceRateWibor1m,
  });

  const setSplitReferenceRate = () => {
    simulationStore.userInput!.additionalRequests.referenceRate = null;
    simulationStore.userInput!.additionalRequests.referenceRateWibor3m = referenceRateWibor3m.value;
    simulationStore.userInput!.additionalRequests.referenceRateWibor6m = referenceRateWibor6m.value;
    simulationStore.userInput!.additionalRequests.referenceRateWibor1m = referenceRateWibor1m.value;
  };

  const setCommonReferenceRate = () => {
    simulationStore.userInput!.additionalRequests.referenceRateWibor3m = null;
    simulationStore.userInput!.additionalRequests.referenceRateWibor6m = null;
    simulationStore.userInput!.additionalRequests.referenceRateWibor1m = null;
    simulationStore.userInput!.additionalRequests.referenceRate = referenceRate.value;
  };

  return {
    referenceRateState,
    selectedReferenceRateType,
    setSplitReferenceRate,
    setCommonReferenceRate,
    referenceRate,
    referenceRateWibor3m,
    referenceRateWibor6m,
    referenceRateWibor1m,
  };
};
