<template>
  <v-card>
    <v-card-title>
      {{ $i18n.useSaveChangesModal.TITLE }}
    </v-card-title>
    <v-divider/>
    <v-card-text class="px-4">
      {{ $i18n.useSaveChangesModal.TEXT }}
    </v-card-text>
    <v-divider/>
    <v-card-actions>
      <fp-btn @click="() => closeModal(undefined)">{{ $i18n.useSaveChangesModal.NULL_TEXT }}</fp-btn>
      <v-spacer/>
      <fp-btn @click="() => closeModal(false)">
        {{ $i18n.useButtonCommons.DO_NOT_SAVE }}
      </fp-btn>
      <fp-btn success @click="() => closeModal(true)">
        {{ $i18n.useButtonCommons.SAVE }}
      </fp-btn>
    </v-card-actions>
  </v-card>
</template>

<script lang="ts">
import {VueModalComponent} from 'fp-components';
import {ModalPayload} from '@/types/typings';
import { Component } from 'vue-property-decorator';

@Component({})
export default class SaveChangesModal extends VueModalComponent<ModalPayload<any>> {
}
</script>
