<template>
  <v-row class="no-gutters">
    <v-col>
      <v-expansion-panels
          accordion
          v-if="store.simulation"
          multiple
          v-model="panelsModel">
        <v-expansion-panel v-if="$vuetify.breakpoint.smAndDown">
          <div class="pa-4">
            <simulation-toolbar-zoom class="mb-3" />
            <simulation-toolbar-sort />
          </div>
        </v-expansion-panel>
        <template v-for="panel in allUserInputPanels">
          <v-expansion-panel
              :key="panel"
              v-if="panel === userInputPanelElementEnum.BASIC_CREDIT_PARAMETERS">
            <v-expansion-panel-header
                class="font-weight-medium body-1">
              {{ I18NGetter().useUserInputPanel.BASIC_PARAMETERS }}
            </v-expansion-panel-header>
            <v-expansion-panel-content class="bt">
              <v-container grid-list-lg class="pa-0">
                <v-layout wrap>
                    <slot name="user-panel-credit-parameters-extension"></slot>
                </v-layout>
              </v-container>
            </v-expansion-panel-content>
          </v-expansion-panel>
            <v-expansion-panel
              :key="panel"
              v-if="panel === userInputPanelElementEnum.BANKS">
              <v-expansion-panel-header
                class="font-weight-medium body-1">
                <span>{{ I18NGetter().useUserInputPanel.BANKS }}</span>
                <fp-btn @click.stop="store.unselectAllBanks()" class="mx-2" v-if="selectedBanks.length">
                  {{ I18NGetter().useUserInputPanel.UNCHECK_ALL }}
                </fp-btn>
              </v-expansion-panel-header>
              <v-expansion-panel-content class="bt">
                <v-row>
                  <v-col class="px-2 py-0 my-n1"
                         :class="$onboarding.tours.MORTGAGE_CALCULATOR.step.BANKS"
                  >
                    <fp-chip-radio
                      v-model="selectedBanks"
                      :multiple="true"
                      :items="banks"/>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          <template v-if="store.isShownAvailableProducts">
              <v-expansion-panel
                :key="panel"
                v-if="panel === userInputPanelElementEnum.INSURANCES && store.isMortgage"
              >
                  <v-expansion-panel-header
                    class="font-weight-medium body-1">
                        <div class="d-flex align-center">
                            {{ I18NGetter().useUserInputPanel.ADD}}
                          <v-tooltip top max-width="350px">
                            <template v-slot:activator="{ on }">
                              <v-icon size="18" class="ml-1" v-on="on">mdi-information-outline</v-icon>
                            </template>
                             <span v-html-safe="I18NGetter().useUserInputPanel.INSURANCES_TEXT"> </span>
                          </v-tooltip>
                        </div>
                  </v-expansion-panel-header>
              <v-expansion-panel-content class="bt">
                <v-row>
                  <v-col>
                      <fp-checkbox
                        v-for="item in extraProductItems"
                        :key="item.type"
                        @change="onFormChange()"
                        v-model="store.userInput.additionalRequests[item.type]">
                        <template #label>
                          <div class="d-flex justify-space-between align-baseline label">
                          <span class="font-weight-medium">
                            {{item.name_pl}}
                          </span>
                          </div>
                        </template>
                      </fp-checkbox>
                      <template v-if="store.userInput.additionalRequests.requiredInsurances">
                        <fp-checkbox
                          v-for="item in insuranceItems"
                          :key="item.type"
                          @change="onFormChange()"
                          v-model="store.userInput.additionalRequests.requiredInsurances[item.type]">
                          <template #label>
                            <div class="d-flex justify-space-between align-baseline label">
                            <span class="font-weight-medium">
                              {{item.name_pl}}
                            </span>
                            </div>
                          </template>
                        </fp-checkbox>
                      </template>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel
                :key="panel"
                v-if="panel === userInputPanelElementEnum.FILTERS">
                <v-expansion-panel-header
                  class="font-weight-medium body-1">
                  <span>{{ I18NGetter().useUserInputPanel.FILTERS }}</span>
                  <fp-btn @click.stop="store.unselectAllFilters()" class="mx-2" v-if="isSomeFilterSelected">
                    {{ I18NGetter().useUserInputPanel.UNCHECK_ALL }}
                  </fp-btn>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="bt">
                  <v-row>
                    <v-col :class="$onboarding.tours.MORTGAGE_CALCULATOR.step.FILTERS" v-if="store.userInput">
                      <div v-if="programOffersFilters?.length" class="body-3 mt-4 mb-2 font-weight-medium text-uppercase">
                       {{I18NGetter().useSimulationMainBadgeHeader.OFFERS}}
                      </div>
                      <offer-filter-checkbox
                        v-for="filter in programOffersFilters"
                        :key="filter.type"
                        v-model="store.userInput.offerFilters[filter.type]"
                        :filter="filter" />
                      <div v-if="offerFeatureFilters?.length" class="body-3 mt-4 mb-2 font-weight-medium text-uppercase">
                        {{I18NGetter().useUserInputPanel.PRODUCT_FEATURES }}
                      </div>
                      <offer-filter-checkbox
                        v-for="filter in offerFeatureFilters"
                        :key="filter.type"
                        v-model="store.userInput.offerFilters[filter.type]"
                        :filter="filter" />
                      <div v-if="interestRateFilters?.length" class="body-3 mt-4 mb-2 font-weight-medium">
                        {{ I18NGetter().useUserInputPanel.INTEREST_RATE }}
                      </div>
                      <offer-filter-checkbox
                        v-for="filter in interestRateFilters"
                        :key="filter.type"
                        v-model="store.userInput.offerFilters[filter.type]"
                        :filter="filter" />
                      <div v-if="referenceRateFilters?.length" class="body-3 mt-4 mb-2 font-weight-medium">
                        {{ I18NGetter().useUserInputPanel.REFERENCE_RATE }}
                      </div>
                      <offer-filter-checkbox
                        v-for="filter in referenceRateFilters"
                        :key="filter.type"
                        v-model="store.userInput.offerFilters[filter.type]"
                        :filter="filter" />
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
          </template>
          <v-expansion-panel
              v-if="panel === userInputPanelElementEnum.SUMMARY"
              :key="panel">
            <v-expansion-panel-header
                class="font-weight-medium body-1">
              {{ I18NGetter().useUserInputPanel.SUMMARY }}
            </v-expansion-panel-header>
            <v-expansion-panel-content class="bt">
              <calculator-summary :loading="store.loading" :simulation="store.simulation"/>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </template>
      </v-expansion-panels>
    </v-col>
  </v-row>
</template>
<script lang="ts" setup>
import {banksDictionary} from '@/commons/dictionaries/BanksDictionary';
import {
  AllUserInputPanels,
  UserInputPanelElementEnum
} from '@/components/calculator/results/userInputPanel/UserInputPanelElementEnum';
import {cloneDeep} from 'lodash-es';
import {computed, Ref, ref} from 'vue';
import ResultSimpleUserInputForm from '@/components/calculator/results/userInputPanel/ResultSimpleUserInputForm.vue';
import CalculatorSummary from '@/components/calculator/results/CalculatorSummary.vue';
import FpChipRadio from '@/components/inputs/FpChipRadio.vue';
import {useSimulationStore} from '@/components/calculator/services/SimulationStore';
import useOfferSorting, {
  FilterCategory,
  OfferFilters
} from '@/components/calculator/results/OfferSorting';
import SimulationToolbarSort from '@/components/calculator/results/toolbar/SimulationToolbarSort.vue';
import SimulationToolbarZoom from '@/components/calculator/results/toolbar/SimulationToolbarZoom.vue';
import {I18NGetter} from '@/services/enumTranslator/I18NGetter';
import {BankSymbol} from '@/commons/dictionaries/BankSymbol';
import {Offer} from '@/models/simulation/Simulation';
import {BankDictionaryElement} from '@/modules/banks/models/BankDictionaryElement';
import OfferFilterCheckbox from '@/components/calculator/results/userInputPanel/OfferFilterCheckbox.vue';

const userInputPanelElementEnum: typeof UserInputPanelElementEnum = UserInputPanelElementEnum;
const allUserInputPanels: UserInputPanelElementEnum[] = AllUserInputPanels;
const store = useSimulationStore();
const {
  insuranceItems,
  extraProductItems,
  getVisibleFiltersByCategory,
} = useOfferSorting();

const userPanels: Ref<UserInputPanelElementEnum[]> = ref(cloneDeep(AllUserInputPanels));
const onFormChange = store.debouncedUpdateSimulation();
const panelsModel = computed({
  get: () => userPanels.value.map((x) => allUserInputPanels.indexOf(x)),
  set: (newPanelsArray) => {
    userPanels.value = allUserInputPanels.filter((x, index) => newPanelsArray.includes(index));
  },
});
const banks = computed(() => {
  const offers: Offer[] = store.isShownAvailableProducts
    ? store.filteredOffers : store.simulation?.refusedOffers ?? [];
  return banksDictionary()
    .filter(x => x.products![store.productType!])
    .map((x: BankDictionaryElement) => ({
      type: x.type,
      name: x.name_pl,
      count: offers.filter(offer => offer.info.bank === x.type).length,
    }));
}
);

const isSomeFilterSelected = computed<boolean>(
  () => !!store.userInput?.offerFilters && Object.values(store.userInput.offerFilters).some(x => x)
);

const selectedBanks = computed<BankSymbol[]>({
  get: () =>
    store.isShownAvailableProducts ? store.userInput!.selectedBanks : store.userInput!.refusedOffers.selectedBanks,
  set: (selectedBanks: BankSymbol[]) => {
    if (store.isShownAvailableProducts) {
          store.userInput!.selectedBanks = selectedBanks;
    } else {
          store.userInput!.refusedOffers.selectedBanks = selectedBanks;
    }
  },
});

const offerFeatureFilters = computed<OfferFilters[]>(
  () => getVisibleFiltersByCategory(FilterCategory.OFFER_FEATURES)
);

const programOffersFilters = computed<OfferFilters[]>(
  () => getVisibleFiltersByCategory(FilterCategory.PROGRAM)
)

const referenceRateFilters = computed<OfferFilters[]>(
  () => getVisibleFiltersByCategory(FilterCategory.REFERENCE_RATE)
)

const interestRateFilters = computed<OfferFilters[]>(
  () => getVisibleFiltersByCategory(FilterCategory.INTEREST_RATE)
);
</script>
<style scoped lang="scss">
::v-deep.fp-checkbox {
  .v-label {
    padding-left: 0;
  }

  label .label {
    width: 100%;

    span {
      font-size: 13px;
      font-style: normal;
    }
  }
}
.v-expansion-panel-header {
  height: 68px;
}
::v-deep.v-btn-toggle {
    display: flex;

  button {
      flex: 1;
  }
}
::v-deep.v-form {
  max-width: 100%;
}
</style>
