<template>
  <v-container fluid class="pa-0">
    <template v-if="!simulationResultConfig">
      {{ $i18n.useSimulationResult.NO_SIMULATION_CONFIG }}
    </template>
    <template v-else>
      <simulation-toolbar :deal="store.deal">
        <template v-if="!isOldView" #left>
          <slot name="toolbar-left"></slot>
        </template>
        <template v-if="!isOldView && store.userInput">
          <fp-btn
            v-model="store.userInput.showOnlySelected"
            :disabled="!hasSelectedOffers"
            height="40px"
            class="px-2 unselect-btn"
            @click="unselectOffers"
          >
            {{ $i18n.useSimulationResult.UNSELECT_ALL_OFFERS }}
          </fp-btn>

              <v-tooltip top v-if="store.isShownAvailableProducts">
                <template v-slot:activator="{ on }">
                  <fp-btn
                    height="40px"
                    class="px-2"
                    :class="$onboarding.tours.MORTGAGE_CALCULATOR.step.SELECTED_OFFERS"
                    v-on="on"
                    :disabled="!hasSelectedOffers"
                    v-model="store.userInput.showOnlySelected"
                    @click="store.userInput.showOnlySelected = !store.userInput.showOnlySelected"
                    :primary="store.userInput.showOnlySelected"
                    text>
                    <v-icon>mdi-checkbox-multiple-outline</v-icon>
                  </fp-btn>
                </template>
                {{ store.userInput.showOnlySelected ? $i18n.useSimulationResult.SHOW_ALL_OFFERS : $i18n.useSimulationResult.SHOW_SELECTED }}
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <fp-btn
                    height="40px"
                    class="px-2"
                    :class="$onboarding.tours.MORTGAGE_CALCULATOR.step.INSTITUTION_NAMES"
                    v-on="on"
                    v-model="store.userInput.hideOfferName"
                    @click="store.toggleHideOfferName()"
                    :primary="store.userInput.hideOfferName"
                    text>
                    <v-icon>mdi-eraser</v-icon>
                  </fp-btn>
                </template>
                {{ store.userInput.hideOfferName ? $i18n.useSimulationResult.SHOW_OFFERS_NAMES : $i18n.useSimulationResult.HIDE_OFFERS_NAMES }}
              </v-tooltip>
              <div class="col shrink" v-if="$vuetify.breakpoint.mdAndUp">
                  <simulation-toolbar-sort />
              </div>
          <v-col class="shrink pl-0">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <fp-btn
                  :class="$onboarding.tours.MORTGAGE_CALCULATOR.step.USED_FILTERS_COUNT"
                  height="40px"
                  v-on="on"
                  v-model="simulationResultConfig.fullscreen"
                  @click="simulationResultConfig.fullscreen = !simulationResultConfig.fullscreen"
                  :primary="!simulationResultConfig.fullscreen"
                  text>
                  <v-badge
                    color="primary"
                    overlap
                    :value="store.selectedFiltersCount"
                    :content="store.selectedFiltersCount"
                    bordered>
                    <v-icon>mdi-tune-variant</v-icon>
                  </v-badge>
                </fp-btn>
              </template>
              {{ simulationResultConfig.fullscreen ? $i18n.useSimulationResult.SHOW_FILTERS : $i18n.useSimulationResult.HIDE_FILTERS }}
            </v-tooltip>
          </v-col>
        </template>
      </simulation-toolbar>
      <v-divider></v-divider>
      <v-window touchless v-model="version" class="window-wrapper"
                :class="{fullscreen: simulationResultConfig.fullscreen}">
        <v-window-item>
          <iframe width="100%"
                  v-if="isOldView"
                  height="1000"
                  :src="iframeUrl"
                  frameborder="0"/>
        </v-window-item>
        <v-window-item>
          <template v-if="!!store.userInput">
            <v-row class="no-gutters offers-column-wrapper">
              <v-col class="offers-column">
                <simulation-different-values-info/>
                <v-progress-linear
                  indeterminate
                  absolute
                  :active="store.loading"/>
                <simulation-offer-listing
                  :style="simulationOfferListingZoom"
                  class="simulation-offer-listing"
                  :data-table-elements="renderedOfferTableElements"/>
                <horizontal-navigation/>
              </v-col>
              <v-col
                class="primaryBg"
                v-show="!simulationResultConfig.fullscreen">
                <user-input-panel class="user-input-bar bl">
                  <template #user-panel-credit-parameters-extension>
                    <slot name="user-panel-credit-parameters-extension"></slot>
                    <v-row class="no-gutters">
                      <v-col class="px-2 pb-1">
                        <v-switch
                          @click.stop="store.createOrUpdateSimulation()"
                          flat
                          class="mt-0"
                          hide-details
                          v-model="store.userInput.additionalRequests.creditAllInitialCosts">
                          <template #label>
                            <span class="body-2 font-weight-medium">
                              {{ I18NGetter().useMortgageSimulationResults.LOAN_INITIAL_COSTS }}
                            </span>
                          </template>
                        </v-switch>
                      </v-col>
                    </v-row>
                    <fp-btn class="my-2 mx-2 flex-fill" @click="store.restoreInitialUserInput()">{{ $i18n.useSimulationResult.RESTORE_INITIAL_USER_INPUT }}
                    </fp-btn>
                  </template>
                </user-input-panel>
              </v-col>
            </v-row>
            <v-row class="submit-simulation-btn" :class="{'fill-width': simulationResultConfig.fullscreen}">
              <v-col class="py-0" :class="{'col-12 mb-2': $vuetify.breakpoint.smAndDown}">
                <fp-btn
                  :class="$onboarding.tours.MORTGAGE_CALCULATOR.step.ADD_OFFERS_TO_DEAL"
                  :click="() => createApplications()"
                  block
                  :clearable="true"
                  primary
                  x-large>
                  <v-icon left>mdi-playlist-plus</v-icon>
                  <span v-if="$vuetify.breakpoint.mdAndDown">
                    {{ $i18n.useSimulationResult.ADD }}
                  </span>
                  <span v-else>
                      {{ $i18n.useSimulationResult.ADD_OFFERS_TO_DEAL }}
                    </span>
                  <span v-if="hasSelectedOffers"
                        class="offers-count body-2 font-weight-medium ml-1">
                    {{ store.selectedOffersCount }}
                  </span>
                </fp-btn>
              </v-col>
              <v-col class="py-0">
                <fp-btn
                  :class="$onboarding.tours.MORTGAGE_CALCULATOR.step.PRINT"
                  block
                  x-large
                  :click="() => openSendOrDownloadModal()">
                  <v-icon left>mdi-file-pdf-box</v-icon>
                  <span v-if="$vuetify.breakpoint.lgAndUp">
                    {{ I18NGetter().useSimulationSendOrDownload.SEND_OR_DOWNLOAD_PDF_WITH_OFFERS }}
                  </span>
                  <span v-else>Wyślij oferty</span>
                  <span v-if="store.selectedOffersCount" class="body-2 text--secondary font-weight-medium ml-1">{{ store.selectedOffersCount }}</span>
                </fp-btn>
              </v-col>
              <v-col class="py-0" v-if="$env.isDev && $vuetify.breakpoint.mdAndUp">
                <fp-btn
                  block
                  x-large
                  :click="() => openPrintoutPreview()">
                  <v-icon left>mdi-dev-to</v-icon>
                  <span v-if="$vuetify.breakpoint.lgAndUp">{{ I18NGetter().useSimulationResult.PRINTOUT_PREVIEW }}</span>
                  <span v-else>{{ I18NGetter().useSimulationResult.PREVIEW }}</span>
                  <span v-if="store.selectedOffersCount" class="body-2 text--secondary font-weight-medium ml-1">{{ store.selectedOffersCount }}</span>
                </fp-btn>
              </v-col>
            </v-row>
          </template>
        </v-window-item>
      </v-window>
    </template>
  </v-container>
</template>
<script lang="ts" setup>
import {ProductType} from '@/commons/enums/ProductType';
import SimulationOfferListing from '@/components/calculator/results/offers/SimulationOfferListing.vue';
import UserInputPanel from '@/components/calculator/results/userInputPanel/UserInputPanel.vue';
import {SimulationResultConfig} from '@/components/calculator/results/SimulationResultConfig';
import Client from '@/models/Client';
import {useSimulationStore} from '@/components/calculator/services/SimulationStore';
import Vue, {computed, defineComponent, getCurrentInstance, onMounted, PropType, ref, Ref} from 'vue';
import SimulationToolbar from '@/components/calculator/results/toolbar/SimulationToolbar.vue';
import {useDealForm} from '@/components/calculator/form/useSimulationForm';
import {useOffersTable} from '@/components/calculator/results/offers/useOffersTable';
import useOfferSorting from '@/components/calculator/results/OfferSorting';
import HorizontalNavigation from '@/components/calculator/results/HorizontalNavigation.vue';
import {useZoom} from '@/components/calculator/results/useZoom';
import SimulationApi from '@/modules/calculator/services/SimulationApi';
import SendOrDownloadOffersModal from '@/components/calculator/printout/SendOrDownloadOffersModal.vue';
import {OfferDataTableElementKey} from '@/components/calculator/results/offers/OfferTableElement';
import {usePrintoutSettings} from '@/components/calculator/printout/usePrintoutSettings';
import SimulationDifferentValuesInfo from '@/components/calculator/results/SimulationDifferentValuesInfo.vue';
import SimulationToolbarSort from '@/components/calculator/results/toolbar/SimulationToolbarSort.vue';
import {CalculatorVersion, useCalculatorVersion} from '@/components/calculator/useCalculatorVersion';
import { I18NGetter } from '@/services/enumTranslator/I18NGetter';
import DealsApi from '@/modules/deals/services/DealsApi';
import SimulationDealPicker from '@/components/calculator/results/SimulationDealPicker.vue';
import AddOffersToDealModal from '@/components/calculator/results/AddOffersToDealModal.vue';
import { useInstance } from '@/composables/useInstance';
import {usePrintout} from '@/components/calculator/printout/usePrintout';
import {useVhHack} from '@/composables/useVhHack';
import {Offer} from '@/models/simulation/Simulation';
import processProxy from '@/env/processProxy';

const props = defineProps<{
  simulationResultConfig: SimulationResultConfig;
}>();

const store = useSimulationStore();
const instance = getCurrentInstance();
const {$modalService, $user, $snackbarService, $goto, $errorHandler, $i18n, } = useInstance();
const printout = usePrintout();
const {fetchDeal,} = useDealForm();
const {setDataTableElementByType, renderedOfferTableElements,} = useOffersTable();
const {
  simulationOfferListingZoom,
} = useZoom(store.productType!, instance?.proxy.$vuetify.breakpoint.mdAndDown);
const {version,} = useCalculatorVersion();

onMounted(() => {
  fetchDeal();
  setDataTableElementByType(store.productType!);
  store.saveInitialUserInput();
  store.isShownAvailableProducts = true;
});

const unselectOffers = async function() {
  try {
    const response = await $modalService.openConfirmModal($i18n.useSimulationResult.UNSELECT_ALL_OFFERS_MODAL_TEXT)
    if (response) {
      await store.unselectedAllOffers()
    }
  } catch (e) {
    $errorHandler(e, $i18n.useSimulationResult.UNSELECT_ALL_OFFERS_ERROR);
  }
}

useVhHack();

const isOldView = computed(() => version.value === CalculatorVersion.V1);

const iframeUrl = computed(() => {
  let prefix: string = '';
  if (store.productType === ProductType.CASH) {
    prefix = 'cash/';
  }
  return `${processProxy.VUE_APP_IFRAME_URL}finrate-iframe/#/${prefix}simulation/${store.simulationId}`;
});

const hasSelectedOffers = computed<boolean>(() => store.selectedOffersCount > 0);

const printOffers = async() => {
  if (hasSelectedOffers.value) {
    await printout.downloadSimulationPrintout();
  } else {
    $snackbarService.openWarningSnackbar(I18NGetter().useSimulationResult
      .PRINT_PREVIEW_OFFERS_WARNING_SNACKBAR);
  }
};

const openPrintoutPreview = () => {
  if (hasSelectedOffers.value) {
    printout.previewSimulationPrintout();
  } else {
    $snackbarService.openWarningSnackbar(I18NGetter().useSimulationResult
      .PRINT_PREVIEW_OFFERS_WARNING_SNACKBAR);
  }
};

const createApplications = async() => {
  if (hasSelectedOffers.value) {
    try {
      const selectedOffers = store.filteredOffers
        .filter(offer => store.userInput?.selectedOffers.includes(offer.info.id)) ?? [];
      if (store.deal) {
        const response: boolean | Offer[] | undefined = await $modalService.open(AddOffersToDealModal, {
          selectedOffers,
          user: $user.value,
        }, { fullscreen: undefined, maxWidth: 800, persistent: true, });
        if (response) {
          const offersToSend: Offer[] = Array.isArray(response) ? response : selectedOffers;
          await DealsApi.updateDeal({
            id: store.deal.id,
            simulationId: store.simulationId!,
            loanAmount: store.userInput?.loanAmount || store.deal.loanAmount,
          });
          await SimulationApi.addOffersToDeal(
            store.deal?.id, store.simulationId!, store.userInput!, offersToSend
          );
          $snackbarService.openSuccessSnackbar(I18NGetter().useSimulationResult
            .CREATE_APPLICATION_OPEN_SUCCESS_SNACKBAR);
          $goto.deal(store.deal?.id);
        }
      } else {
        await $modalService.open<Client>(SimulationDealPicker, {
          productType: store.productType,
          simulationId: store.simulationId,
          userInput: store.userInput,
          offers: selectedOffers,
        });
      }
    } catch (e) {
      $errorHandler(e, I18NGetter().useSimulationResult
        .CREATE_APPLICATION_ERROR_HANDLER);
    }
  } else {
    $snackbarService.openWarningSnackbar(I18NGetter().useSimulationResult
      .CREATE_APPLICATION_OPEN_WARNING_SNACKBAR);
  }
};
const openSendOrDownloadModal = async() => {
  const selectedOffers: Offer[] = store.simulation?.offers.filter(offer => {
    return store.userInput?.selectedOffers.includes(offer.info?.id);
  }) ?? [];
  if (hasSelectedOffers.value) {
    await $modalService.open(SendOrDownloadOffersModal,
      {
        productType: store.productType!,
        selectedOffers,
        userInput: store.userInput,
      });
  } else {
    $snackbarService.openWarningSnackbar(I18NGetter().useSimulationResult
      .PRINT_PREVIEW_OFFERS_WARNING_SNACKBAR);
  }
};
</script>

<style scoped lang="scss">
:deep(.v-toolbar__content) {
  padding-left: 4px;
  padding-right: 4px;
}

.window-wrapper.fullscreen {
  /*TODO: ten mechanizm trzeba bedzie przerobić*/
  --user-bar-width: 0px !important;
}

.unselect-btn {
  display: block;

  @media screen and (max-width: 800px) {
    display: none;
  }
}

.window-wrapper {
  --user-bar-width: 310px;
  --submit-simulation-button-height: 76px;
  --border-size: 1px;
  $different-values-offset: 57px;
  $different-values-mobile-device-offset: 112px;
  $element-height: calc(var(--vh, 1vh) * 100 - #{$main-header-height} - #{$navigation-header-height} - var(--border-size) - var(--submit-simulation-button-height));
  @media screen and (max-width: 960px) {
    --submit-simulation-button-height: 136px;
  }
  @-moz-document url-prefix() {
    // a hack that hides an unnecessary scroll on mozilla after reducing the size of the offer view on S or M
    .offers-column-wrapper {
      overflow: hidden;
    }
  }

  .different-values-info + .no-gutters {
    .user-input-bar, .offers-column {
      height: calc(#{$element-height} - #{$different-values-offset});

      @media screen and (max-width: 600px) {
        height: calc(#{$element-height} - #{$different-values-mobile-device-offset});
      }
    }
  }

  .user-input-bar {
    height: $element-height;
    position: sticky;
    top: var(--main-header-height);
    width: var(--user-bar-width);
    min-width: var(--user-bar-width);
    right: 0;
    overflow: auto;
  }

  .offers-column {
    flex-basis: calc(100% - var(--user-bar-width));
    max-width: calc(100% - var(--user-bar-width));
    height: $element-height;
    position: relative;
  }

  display: contents;
}

::v-deep .v-expansion-panel-content__wrap {
  padding-top: 16px;
  padding-right: 16px;
  padding-left: 16px;
}

.submit-simulation-btn {
  background: var(--v-primaryBg-base);
  padding: 12px;
  width: 100%;
  height: var(--submit-simulation-button-height);

  .offers-count {
    color: rgba(#fff, .7);
  }
}

.zoom-input {
  max-width: 120px;
}

.simulation-offer-listing {
  flex-direction: column;
  overflow: auto;

  &::-webkit-scrollbar:horizontal {
    height: 24px;
  }

  &::-webkit-scrollbar-track:horizontal {
    background-color: fpShadow($border-opacity);
    box-shadow: none;
  }

  &::-webkit-scrollbar-thumb:horizontal {
    background-color: fpShadow($secondary-opacity);
    min-width: 24px;
    margin: 4px 0;
    background-clip: padding-box;
    box-shadow: none;
    border-radius: 12px;
  }

  &::-webkit-scrollbar-corner {
    background-color: fpShadow($border-opacity);
  }

  &::-webkit-scrollbar-button:horizontal {
    background-size: auto;
    background-color: fpShadow($border-opacity);
    background-repeat: no-repeat;
    width: 30px;
    height: 24px;

    &:start:decrement {
      width: 12px;
    }

    &:start:increment, &:end:increment, &:end:decrement {
      display: none;
    }
  }
}
</style>
