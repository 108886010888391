import {debouncedAtInput} from '@/services/utils/BasicUtils';
import {
  CreditCardDemand,
  InferredReplacement,
  Replacement,
  ReplacementKind,
  Replacements
} from '@/models/Demand';
import {useSimulationStore} from '@/components/calculator/services/SimulationStore';
import {Offer} from '@/models/simulation/Simulation';
import {I18NGetter} from '@/services/enumTranslator/I18NGetter';
import Vue from 'vue';
import {ProductType} from '@/commons/enums/ProductType';
import {i18nFormatter} from '@/services/StringFormatter';
import {useCalculatorSettings} from '@/components/calculator/useCalculatorSettings';

export const useOfferReplacements = (offer: Offer) => {
  const {INPUT_DEBOUNCE_TIME_IN_MILLISECONDS,} = useCalculatorSettings();

  const store = useSimulationStore();

  async function manageReplacement(replacement: Partial<InferredReplacement<typeof replacementKind>>, replacementKind: ReplacementKind) {
    const loanId: string = offer.info.id;
    if (!store.userInput!.additionalRequests.replacements) {
      store.userInput!.additionalRequests.replacements = new Replacements();
    }
    const newReplacement: InferredReplacement<typeof replacementKind> = {loanId, ...replacement,};
    const foundReplacement: InferredReplacement<typeof replacementKind> | undefined = (store.userInput!.additionalRequests
      .replacements[replacementKind] as InferredReplacement<typeof replacementKind>[])
      .find(x => x.loanId === loanId);
    if (!foundReplacement) {
      (store.userInput!.additionalRequests.replacements[replacementKind] as InferredReplacement<typeof replacementKind>[]).push(newReplacement);
    } else {
      const index = store.userInput!.additionalRequests
        .replacements[replacementKind]
        .findIndex(x => x.loanId === loanId);
      store.setReplacement(replacementKind, index, newReplacement);
    }
    await store.createSimulation(loanId);
  }

  async function manageSimulationReplacement(replacement: Partial<Replacement>, replacementKind: ReplacementKind) {
    await manageReplacement(replacement, replacementKind);
    await store.createSimulation(offer.info.id);
  }

  async function manageSimulationReplacementIfAvailable(replacement: Partial<Replacement>, replacementKind: ReplacementKind) {
    await manageReplacement(replacement, replacementKind);
    await store.createSimulationIfAvailable(offer.info.id);
  }

  const manageCreditCardDemand = async(acquired: boolean) => {
    const loanId = offer.info.id;
    if (!store.userInput!.additionalRequests.replacements) {
      store.userInput!.additionalRequests.replacements = new Replacements();
    }
    const newReplacement: CreditCardDemand = {loanId, acquired,};
    const foundReplacement = store.userInput!.additionalRequests
      .creditCardDemands?.find(x => x.loanId === loanId);
    if (!foundReplacement) {
      store.userInput!.additionalRequests.creditCardDemands = [...store.userInput!.additionalRequests.creditCardDemands, newReplacement,];
    } else {
      foundReplacement.acquired = acquired;
    }
    await store.createSimulationIfAvailable(loanId);
  };

  const onProvisionPercentageChange = async(value: number) => {
    const MAX_PROVISION_PERCENTAGE: number = 100;
    const MIN_PROVISION_PERCENTAGE: number = 0;
    const minPercentage: number = offer.initialCosts.provision.minPercentage ?? MIN_PROVISION_PERCENTAGE;
    const maxPercentage: number = offer.initialCosts.provision.maxPercentage ?? MAX_PROVISION_PERCENTAGE;
    const newValue: number = Math.min(Math.max(value, minPercentage), maxPercentage);
    await manageSimulationReplacement({newValue,}, ReplacementKind.PROVISION);
    if (value !== newValue) {
      Vue.prototype.$snackbarService.openWarningSnackbar(
        `${value > newValue ? i18nFormatter(I18NGetter().useOfferReplacement.NEW_TRANSLATION_PROVISION_WARNING_SNACKBAR_MAXIMUM, {newValue: newValue,})
          : i18nFormatter(I18NGetter().useOfferReplacement.NEW_TRANSLATION_PROVISION_WARNING_SNACKBAR_MINIMUM, {newValue: newValue,})}`
      );
    }
  }

  const onBrokerCommissionChange = async(value: number) => {
    const MAX_PROVISION_PERCENTAGE: number = 30;
    const newValue: number = Math.min(value, MAX_PROVISION_PERCENTAGE);
    await manageSimulationReplacement({newValue,}, ReplacementKind.BROKER_COMMISSION);
  }

  const onMarginChange = async(newInitialValue: number) => {
    await manageSimulationReplacementIfAvailable({newInitialValue,}, ReplacementKind.MARGIN);
  }

  const onEventualMarginChange = async(newEventualValue: number) => {
    await manageSimulationReplacementIfAvailable({newEventualValue,}, ReplacementKind.MARGIN);
  }

  const onCustomPeriodChange = async(value: number) => {
    const maxLoanPeriodInMonths: Nullable<number> = offer.restrictions?.loanPeriodRestrictions.maxLoanPeriod ?? null;
    const minLoanPeriodInMonths: Nullable<number> = offer.restrictions?.loanPeriodRestrictions.minLoanPeriod ?? null;
    let newLoanPeriodInMonths: number = value;
    if (maxLoanPeriodInMonths && newLoanPeriodInMonths > maxLoanPeriodInMonths) {
      newLoanPeriodInMonths = maxLoanPeriodInMonths;
    } else if (minLoanPeriodInMonths && newLoanPeriodInMonths < minLoanPeriodInMonths) {
      newLoanPeriodInMonths = minLoanPeriodInMonths;
    }
    await manageSimulationReplacementIfAvailable({newLoanPeriodInMonths,}, ReplacementKind.LOAN_PERIOD);
    if (value !== newLoanPeriodInMonths) {
      Vue.prototype.$snackbarService.openWarningSnackbar(
        `${value > (maxLoanPeriodInMonths ?? 0) ? i18nFormatter(
          I18NGetter().useOfferReplacement.NEW_TRANSLATION_WARNING_SNACKBAR_MAXIMUM_MALE,
          {newLoanPeriodInMonths: newLoanPeriodInMonths,})
          : i18nFormatter(
            I18NGetter().useOfferReplacement.NEW_TRANSLATION_WARNING_SNACKBAR_MINIMUM_MALE,
            {newLoanPeriodInMonths: newLoanPeriodInMonths,})}`);
    }
  }

  const onCustomAmountChange = async(newValue: number) => {
   await manageSimulationReplacementIfAvailable({newValue,}, ReplacementKind.LOAN_AMOUNT);
  }

  return {
    onProvisionPercentageChange,
    onBrokerCommissionChange,
    onMarginChange,
    onEventualMarginChange,
    onCustomPeriodChange,
    onCustomAmountChange,
    manageCreditCardDemand,
  };
};
