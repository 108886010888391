<template>
  <v-card>
    <v-card-title>
      {{ $i18n.useUndoChangesModal.TITLE }}
    </v-card-title>
    <v-card-text class="px-4">
      {{ $i18n.useUndoChangesModal.TEXT }}
    </v-card-text>
    <v-divider/>
    <v-card-actions>
      <v-spacer/>
      <fp-btn @click="() => closeModal(false)">
        {{ $i18n.useButtonCommons.NO }}
      </fp-btn>
      <fp-btn primary @click="() => closeModal(true)">
        {{ $i18n.useButtonCommons.YES }}
      </fp-btn>
    </v-card-actions>
  </v-card>
</template>

<script lang="ts">
import {VueModalComponent} from 'fp-components';
import {ModalPayload} from '@/types/typings';
import { Component } from 'vue-property-decorator';

@Component({})
export default class RevertChangesModal extends VueModalComponent<ModalPayload<any>> {
}
</script>

<style scoped>

</style>
