<template>
  <v-row>
    <v-col>
      <p v-if="label" class="font-weight-medium mb-1">{{ label }}</p>
      <v-item-group :value="value" :multiple="multiple" :id="id" :mandatory="mandatory">
        <v-item v-for="(item, index) in items" :key="index" :value="item[itemKey]">
          <v-chip :color="chipColor(active, item) + '33'"
                  :text-color="chipColor(active, item)"
                  class="ml-0 mr-1"
                  slot-scope="{ active }"
                  @click="onClick(item[itemKey])">
            <v-icon small v-if="item[itemIcon]" class="mr-1">{{ item[itemIcon] }}</v-icon>
              {{ item[itemName] }}
            <div v-if="Number.isInteger(item[itemCount])" class="count ml-1">{{ item[itemCount] }}</div>
          </v-chip>
        </v-item>
      </v-item-group>
    </v-col>
    <v-col cols="12" v-if="hasError">
      <span class="caption error--text">Pole wymagane</span>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import {Component, Inject, Prop, Vue, Watch} from 'vue-property-decorator';
import {Utils} from '@/services/utils/BasicUtils';
import {VuetifyThemeItem} from 'vuetify/types/services/theme';
import {UserLocalStorage} from '@/services/UserLocalStorage';

interface FpChipRadioPropsValidation {
  readonly required: boolean;
}

type FpChipRadioModel = String | number | (String | number)[];

@Component
export default class FpChipRadio extends Vue implements IValidatable {
  @Prop({ required: true, })
  public readonly value!: FpChipRadioModel;

  @Prop({
    default: () => ([{
      type: true,
      name: 'Tak',
    },
    {
      type: false,
      name: 'Nie',
    },])
    ,
  })
  public readonly items!: Array<any>;

  @Prop({default: false,})
  public readonly required!: boolean;

  @Prop({default: false,})
  public readonly multiple!: boolean;

  @Prop({type: String,})
  public readonly label?: string;

  @Prop({default: 'type',})
  public readonly itemKey!: string;

  @Prop({default: 'name',})
  public readonly itemName!: string;

  @Prop({default: 'icon',})
  public readonly itemIcon!: string;

  @Prop({default: 'count',})
  public readonly itemCount!: string;

  @Prop({default: undefined, type: String,})
  public readonly prependIcon!: string;

  @Prop({default: 'color',})
  public readonly itemColor!: string;

  @Prop()
  public readonly localStorageKey!: string;

  @Prop()
  public readonly id?: string;

  @Prop({default: false,})
  public readonly mandatory!: boolean;

  @Inject({from: 'form', default: () => null,})
  public form?: IForm;

  @Watch('required')
  onRulesChange() {
    this.resetValidation();
  }

  public hasError = false;
  public readonly rules: ValidationRules<FpChipRadioPropsValidation, FpChipRadioModel> = {
    required: (v) => Array.isArray(v) ? v.length > 0 : !!v,
  };

  created(): void {
    if (this.form) {
      this.form.register(this);
    }
  }

  destroyed(): void {
    if (this.form) {
      this.form.unregister(this);
    }
  }

  chipColor(active: boolean, item: any): string | VuetifyThemeItem {
    return this.hasError
      ? this.$vuetify.theme.currentTheme.error
      : active
        ? item[this.itemColor] || this.$vuetify.theme.currentTheme.primary
        : '';
  }

  updateSelectedTags(value: any): void {
    if (!this.multiple && !this.mandatory && value === this.value) {
      value = null;
    }
    if (this.value !== value) {
      this.$emit('input', value);
    }
    if (this.localStorageKey) {
      UserLocalStorage.setItem(this.localStorageKey, typeof value === 'object' ? JSON.stringify(value) : value);
    }
  }

  onClick(tag?: string): void {
    this.resetValidation();
    if (tag !== undefined) {
      if (this.multiple) {
        if (Array.isArray(this.value)) {
          if (!this.value.includes(tag)) {
            this.value.push(tag);
            this.updateSelectedTags(this.value);
          } else {
            this.updateSelectedTags(this.value.filter(x => x !== tag));
          }
        } else {
          this.updateSelectedTags([tag,]);
        }
      } else {
        this.updateSelectedTags(tag);
      }
    } else {
      console.error('Tag is invalid', tag);
      if (this.items[0] && !this.items[0][this.itemKey]) {
        console.error('itemKey is invalid', this.itemKey);
      }
    }
  }

  resetValidation(): void {
    this.hasError = false;
  }

  validate(): boolean {
    this.hasError = Utils.keys<FpChipRadioPropsValidation>(this.rules)
      .map((key) => (this[key] === false || this[key] === undefined) || this.rules[key]!(this.value))
      .includes(false);
    return !this.hasError;
  }
}

</script>

<style scoped lang="scss">
span ::v-deep .v-chip__content {
  cursor: pointer;
}

.count {
  width: 20px;
  text-align: center;
  border-radius: 100%;
  background-color: #fff;
}

.theme--dark .count {
  background-color: fpShadow(.15);
}

</style>
