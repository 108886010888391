var render = function render(){var _vm=this,_c=_vm._self._c;return _c('fp-field',{attrs:{"keys":_vm.$props}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return _c('VTextField',{ref:"fpInput",class:{
        dashed: _vm.dashed,
        'fp-box-input-style': _vm.box,
        'fp-input-outline': _vm.outline,
        'no-hint': !!_vm.value,
      },attrs:{"filled":_vm.box,"label":_vm.label,"id":_vm.id,"error":_vm.error,"success":_vm.success,"maxlength":_vm.maxLength,"dense":_vm.dense,"value":_vm.model,"error-messages":_vm.errorMessages,"inputmode":_vm.isNumber ? 'numeric' : '',"pattern":_vm.pattern ? _vm.pattern : _vm.isNumber ? '[0-9]*' : '',"rules":_vm.rules,"validate-on-blur":_vm.validateOnBlur,"type":_vm.email ? 'email' : _vm.isNumber ? '' : _vm.password ? (_vm.showPassword ? 'text' : 'password') : _vm.type,"required":_vm.required,"outlined":_vm.outline,"placeholder":_vm.persistantPlaceholder || hover || _vm.isFocused ? _vm.placeholder : undefined,"disabled":_vm.disabled,"hide-details":_vm.hideDetails || (_vm.errorMessages.length === 0 && _vm.valid),"suffix":_vm.suffix,"prefix":_vm.prefix,"clearable":!_vm.isNumber || !_vm.phone ? _vm.clearable && Boolean(_vm.value) && typeof _vm.value === 'string' && _vm.value.length > 0 : false,"persistent-hint":"","auto-grow":!_vm.isNumber,"counter":_vm.counter,"loading":_vm.loading,"single-line":!_vm.dense ? _vm.singleLine : true,"multi-line":_vm.multiLine,"rows":"1","autocomplete":_vm.autocomplete,"hint":_vm.description,"append-outer-icon":_vm.appendOuterIcon,"append-icon":_vm.password ? (_vm.showPassword ? 'visibility' : 'visibility_off') : _vm.appendIcon ?? undefined,"prepend-icon":_vm.prependIcon,"prepend-inner-icon":_vm.prependInnerIcon},on:{"focus":_vm.focusCallback,"click":_vm.clickCallback,"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.onEnter.apply(null, arguments)},"input":_vm.inputCallback,"change":_vm.changeCallback,"paste":_vm.pasteCallback,"blur":function($event){return _vm.blurCallback($event.target.value)},"click:clear":_vm.clearCallback,"click:prepend-inner":function($event){return _vm.$emit('click:prepend-inner')},"click:append":function($event){_vm.$emit('click:append');
        _vm.showPassword = !_vm.showPassword;}},scopedSlots:_vm._u([{key:"append",fn:function(){return [_vm._t("append")]},proxy:true},{key:"message",fn:function(data){return [_vm._t("message",null,{"message":data.message}),_vm._v(" "),(!_vm.$scopedSlots.message)?_c('span',[_vm._v(_vm._s(data.message))]):_vm._e()]}},{key:"append-outer",fn:function(){return [_vm._t("append-outer")]},proxy:true}],null,true)})}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }