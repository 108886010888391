<template>
  <v-tooltip v-if="icon" left>
    <template #activator="{on}">
      <v-icon v-on="on">{{ icon }}</v-icon>
    </template>
    <span>{{ referenceRate }}</span>
  </v-tooltip>
</template>
<script lang="ts" setup>

import {ReferenceRateType} from '@/models/simulation/ReferenceRateType';
import {computed} from 'vue';

const props = defineProps<{
  referenceRate: ReferenceRateType;
}>();

const icon = computed<string>(() => {
  switch (props.referenceRate) {
  case ReferenceRateType.WIBOR_3M:
    return 'mdi-numeric-3-box-outline';
  case ReferenceRateType.WIBOR_6M:
    return 'mdi-numeric-6-box-outline';
  case ReferenceRateType.WIRON_1M:
    return 'mdi-numeric-1-box';
  case ReferenceRateType.WIBOR_1M:
    return 'mdi-numeric-1-box-outline';
  default:
    return '';
  }
});
</script>
