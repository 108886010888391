<template>
  <v-layout>
    <v-flex>
      <v-combobox
          :id="$cy.comboboxClientAutocomplete"
          :value="value"
          :items="clients"
          @input="onInput"
          :searchInput="search"
          @update:search-input="onSearchUpdateDebounced"
          :multiple="!singular"
          no-filter
          item-value="id"
          item-text="displayName"
          color="primary"
          hide-details="auto"
          :menu-props="{'closeOnContentClick': true,}"
          ref="clientAutocomplete"
          :dense="dense"
          :required="required"
          :return-object="!returnId"
          :rules="required ? rules : undefined"
          :label="label"
          :disabled="loading || disabled"
          class="fp-input-outline"
          hide-selected
          :loading="loading"
          outlined
          prepend-icon="mdi-account-search">
        <template slot="selection" slot-scope="{item}">
          <v-chip :close="!disabled" @click:close="removeClient($event,  item.id ? item.id : item)">
            <span class="client-chip-text">
              {{ getClientDisplayName(item)}}
            </span>
          </v-chip>
        </template>
        <template slot="item" slot-scope="{ item }">
          <client-badge class="pa-1 ma-1" hide-more :hideActions="true" removeLink :client="item"></client-badge>
        </template>
        <template v-slot:append-item>
          <div v-intersect="onIntersect" />
          <v-progress-linear v-if="paginatedResults && loading" indeterminate />
        </template>
        <template v-slot:no-data>
          <span class="px-2">{{ I18NGetter().useClientAutocomplete.NO_CLIENTS }}</span>
        </template>
      </v-combobox>
    </v-flex>
    <v-flex shrink class="align-self-center ml-4" v-if="create">
      <v-tooltip top>
        <template v-slot:activator="{on}">
          <v-btn v-on="on"
                 @click="addClient()"
                 class="mx-0"
                 fab small
                 depressed
                 :disabled="disabled"
                 :color="$vuetify.theme.currentTheme.primary +'33'">
            <v-icon large color="primary">add</v-icon>
          </v-btn>
        </template>
        <span>{{ I18NGetter().useClientAutocomplete.ADD_NEW_CLIENT }}</span>
      </v-tooltip>
    </v-flex>
  </v-layout>
</template>

<script lang="ts">
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import Client from '../../models/Client';
import ClientStore from '@/modules/client/services/store/ClientStore';
import FuzzySearch from '@/services/searchEngine/FuzzySearch';
import ClientBadge from '@/components/badges/ClientBadge.vue';
import {ModalPayload} from '@/types/typings';
import CreateClient from '@/modules/client/views/create-client/CreateClient.vue';
import { Utils, debouncedAtInput } from '@/services/utils/BasicUtils';
import ClientApi from '@/modules/client/services/ClientApi';
import { ClientFilter } from '@/models/Filter';
import {I18NInterface} from '@/services/enumTranslator/I18NInterface';
import {I18NGetter} from '@/services/enumTranslator/I18NGetter';

@Component({
  components: {ClientBadge, },
})
export default class ClientAutocomplete extends Vue {
  @Prop()
  public readonly value!: Client | Array<Client> | Array<number>;

  @Prop({default: I18NGetter().useClientAutocomplete.SEARCH_CLIENT, })
  public readonly label!: string;

  @Prop({default: null, })
  public readonly userId!: Nullable<number>;

  @Prop({default: false, type: Boolean, })
  public readonly returnId!: boolean;

  @Prop({default: false, type: Boolean, })
  public readonly required!: boolean;

  @Prop({default: false, type: Boolean, })
  public readonly autoFocus!: boolean;

  @Prop({default: false, type: Boolean, })
  public readonly disabled!: boolean;

  @Prop({default: false, type: Boolean, })
  public readonly singular!: boolean;

  @Prop({default: false, type: Boolean, })
  public readonly create!: boolean;

  @Prop({default: false, type: Boolean, })
  public readonly dense!: boolean;

  @Prop({default: false, type: Boolean, })
  public readonly multiple!: boolean;

  @Prop({default: null, })
  public readonly filter?: (c: Client) => boolean;

  @Prop({default: undefined, })
  public readonly prependIcon?: string | undefined;

  @Prop({default: () => [], })
  public readonly excludedClients!: Array<Client>;

  @Prop({default: undefined, })
  public readonly paginated?: boolean;

  @Prop({default: undefined, })
  public readonly isCompany?: boolean;

  @Prop({default: false, type: Boolean, })
  public readonly hideClientCreateTypeTabs!: boolean;

  public excludedClientsIds: Array<number> = [];

  public options: ClientFilter = new ClientFilter();
  public clients: Client[] = [];
  public loading: boolean = false;
  private selectedClients: Array<Client> = [];
  public search: string = '';
  public I18NGetter: () => I18NInterface = I18NGetter;
  public rules = [
    (v: any) => !Utils.isEmptyValue(v) || I18NGetter().useClientAutocomplete.FIELD_REQUIRED,
  ];

  @Watch('isCompany')
  async onFilterChange(newValue: Function, oldValue: Function) {
    this.clients = await this.getClientsList();
  }

  private filterClients(clients: Array<Client>) {
    let filteredClients = clients;
    if (this.excludedClientsIds?.length) {
      filteredClients = clients.filter(c => !this.excludedClientsIds.includes(c.id!));
    }

    if (this.filter) {
      filteredClients = filteredClients.filter(this.filter);
    }

    return filteredClients;
  }

  public async getClientsList() {
    return this.filterClients(
      this.paginatedResults
        ? await this.getPaginatedClientsList()
        : FuzzySearch.sortAndFilter<Client>(ClientStore.getClients, this.search!));
  }

  public async getPaginatedClientsList() {
    this.options.name = this.search;
    this.options.isCompany = this.isCompany;
    const response = await ClientApi.filterClients(this.options);
    return response.items;
  }

  public get paginatedResults(): boolean {
    return this.paginated ??
      (this.$user.isAdminOrVerifier || this.$user.isChiefOfOrganisation || this.$user.isChiefOfDivision);
  }

  async mounted() {
    this.excludedClientsIds = this.excludedClients.map(c => c.id!);
    await this.fetchClients();
    if (this.autoFocus) {
      this.setFocus();
    }
  }

  private async fetchClients() {
    this.loading = true;
    if (!this.paginatedResults) {
      await ClientStore.fetchClients();
    }

    this.clients = await this.getClientsList();
    this.loading = false;
  }

  private setFocus() {
    setTimeout(() => {
      const ref: any = this.$refs?.clientAutocomplete;
      ref.focus();
    }, 100);
  }

  onSearchUpdateDebounced = debouncedAtInput(async(event) => {
    await this.onSearchUpdate(event);
  });

  public async onSearchUpdate(event: any) {
    if (event === null || event === this.search) {
      return;
    }

    this.options.page = 1;
    this.search = event;
    this.clients = await this.getClientsList();
  }

  public async onIntersect(entries: any, observer: any, isIntersecting: boolean) {
    if (!this.paginatedResults || !isIntersecting) {
      return;
    }

    this.options.page++;
    this.loading = true;
    const response = await this.getPaginatedClientsList();
    this.clients.push(...response);
    this.loading = false;
  }

  public removeClient(event: boolean, id: number) {
    if (!event) {
      const val = this.singular ? null : (this.value as any).filter((v: any) => this.returnId ? v !== id : v.id !== id);
      this.emitValue(val);
    }
  }

  public getClientDisplayName(client: Client | number) {
    if (!this.returnId && typeof client === 'object') {
      return client.displayName;
    }

    const clients = this.paginatedResults
      ? this.selectedClients
      : ClientStore.getClients;
    const foundedClient = clients.find(item => item.id === client);

    return foundedClient?.displayName || '';
  }

  public emitValue(value: Client | Array<Client> | Array<number>) {
    this.$emit('input', value);
  }

  public async addClient() {
    const modalResponse =
        await this.$modalService.open<ModalPayload<Client>>(CreateClient, { hideClientTypeTabs: this.hideClientCreateTypeTabs, },
          {maxWidth: 650, persistent: true, fullscreen: undefined, });
    if (modalResponse && modalResponse.payload) {
      const client = new Client(modalResponse.payload);
      this.clients.push(client);
      this.onInput(client);
    }
  }

  public async onInput(event: any) {
    if (event && this.paginatedResults) {
      const client = this.returnId ? this.clients.find(client => client.id === Number(event)) : event;
      if (client) {
        this.selectedClients.push(client);
      }
    }

    if (this.search) {
      this.options.page = 1;
      this.search = '';
      this.clients = await this.getClientsList();
      if (this.isCompany !== undefined) {
        this.clients = this.clients.filter(client => client.isCompany === this.isCompany);
      }
    }

    if (typeof event !== 'string') {
      if (Array.isArray(event)) {
        this.emitValue(event.filter((e: any) => typeof e !== 'string'));
      } else {
        this.emitValue(event);
      }
    }
  }
}

</script>

<style scoped lang="scss">
  ::v-deep.v-list-item__avatar{
    min-width: 46px;
  }

  ::v-deep.v-list-item{
    padding-left: 8px!important;
  }

  .client-chip-text {
    font-size: 16px;
    color: rgba(35, 45, 59, $primary-opacity);
  }

  ::v-deep.theme--light.v-text-field--outline > .v-input__control > .v-input__slot {
    border: solid 1px rgba(35, 45, 59, 0.15);
  }

  ::v-deep .v-input__append-inner {
    cursor: pointer;
  }

</style>
