import { Person } from '@/models/Person';
import { Searchable } from '@/services/searchEngine/fuzzySearch/Searchable';
import { Account, Address, PersonalDetails } from '@/modules/multiForm/shared/MultiformModels';
import MultiformDictionary from '@/modules/multiForm/shared/MultiformDictionaries';
import moment from 'moment';
import { addressFormat } from '@/filters/StringFilters';
import AuthService from '@/modules/user/AuthService';
import { PersonalIdEnum } from '@/modules/client/enums/PersonalIdEnum';
import { IClientable } from '@/models/interfaces/IClientable';
import { Agreements, AgreementType } from '@/modules/client/views/client-agreements/Agreements';
import { IClientBadgeViewModel } from '@/modules/client/models/ClientBadgeViewModel';
import { clone, Utils } from '@/services/utils/BasicUtils';
import ClientScans from '@/models/clientScans/ClientScans';
import { Vue } from 'vue-property-decorator';
import User from '@/models/user/User';
import { ClientDetailsSegments } from '@/types/Dictionaries';
import Partner from '@/models/Partner';
import { ClientScan } from '@/models/clientScans/ClientScanModel';
import EnvironmentService from '@/env/EnvironmentService';
import { Gender } from '@/models/enums/Gender';
import { CrmModules } from '@/types/CrmModules';

const PERSONAL_ID_DATE_THRESHOLD: number = 10;

export class PersonalIdDto {
  id?: number;
  idType: PersonalIdEnum = PersonalIdEnum.PERSONAL_ID;
  series: Nullable<string> = null;
  number: Nullable<string> = null;
  institution: Nullable<string> = null;
  dateIssued: Nullable<string> = null;
  expiryDate: Nullable<string> = null;
  isMain?: boolean;
}

export class PersonalId extends PersonalIdDto {
  public get fullPersonalIdNumber(): string {
    return (this.series && this.number) ? this.series + this.number : '';
  }

  public get personalIdNamePl(): string | null {
    return MultiformDictionary.documentTypesDictionary.find(x => x.type === this.idType)?.name_pl || null;
  }

  public get dateIssuedTime(): number | null {
    return this.dateIssued ? (new Date(this.dateIssued)).getTime() : null;
  }

  public get expiryDateTime(): number | null {
    return this.expiryDate ? (new Date(this.expiryDate)).getTime() : null;
  }

  public get dateIssuedModel(): Date | null {
    return this.dateIssued ? new Date(this.dateIssued) : null;
  }

  public set dateIssuedModel(newValue: Date | null) {
    this.dateIssued = newValue?.toISOString() ?? null;
    if (newValue && this.expiryDate === null) {
      const newDate: Date = new Date(newValue.valueOf());
      newDate.setFullYear(newDate.getFullYear() + PERSONAL_ID_DATE_THRESHOLD);
      this.expiryDateModel = newDate;
    }
  }

  public get expiryDateModel(): Date | null {
    return this.expiryDate ? new Date(this.expiryDate) : null;
  }

  public set expiryDateModel(newValue: Date | null) {
    this.expiryDate = newValue?.toISOString() ?? null;
    if (newValue && this.dateIssued === null) {
      const newDate: Date = new Date(newValue.valueOf());
      newDate.setFullYear(newDate.getFullYear() - PERSONAL_ID_DATE_THRESHOLD);
      this.dateIssuedModel = newDate;
    }
  }

  constructor(index: number = 0, dto?: PersonalIdDto) {
    super();
    if (dto) {
      Utils.assign(this, dto);
    }
    if (index) {
      this.isMain = false;
    }
  }
}

export abstract class ClientDTO extends Person implements IClientable {
  public ownerId!: number;
  public widgetOwnerId?: number;
  public delegatedTo?: Person;
  public owner?: User;
  public name: string | null = null;
  public nip: Nullable<string> = null;
  public regon: Nullable<string> = null;
  public agreements: Agreements = new Agreements();
  public agreementMethod!: AgreementType;
  public isCompany: boolean = false;
  public addedAt!: string;
  public modifiedAt!: string;
  public lastDeal?: { dealName: string, dealId: number };
  public per?: { dealName: string, dealId: number };
  public mainAddress: Address = new Address();
  public primaryAddress: Nullable<Address> = null;
  public secondaryAddress: Nullable<Address> = null;
  public personalIds: Array<PersonalId> = [];
  public clientDataJson: Nullable<PersonalDetails> = null;
  public isEditableByClient!: boolean;
  public primaryAddressAsMainAddress: boolean = true;
  public secondaryAddressAsMainAddress: boolean = true;
  public partnerId?: number;
  public partnerSourceId?: Nullable<number> = null;
  public requireBankSecrecy?: Nullable<boolean> = null;
  public isFromProspect?: Nullable<boolean> = null;
  public birthDate?: Nullable<string> = null;
}

const clientObjectPickKeys: (keyof Client)[] = ['isCompany', 'firstName', 'lastName', 'agreements', 'checkedBankSecrecySentEmailApprove', 'pesel', 'agreementMethod', 'phone', 'mainAddress', 'email', 'smsCode',];
const companyObjectPickKeys: (keyof Client)[] = ['isCompany', 'name', 'nip', 'regon', 'agreements', 'checkedBankSecrecySentEmailApprove', 'agreementMethod', 'phone', 'mainAddress', 'email', 'smsCode',];

export default class Client extends ClientDTO implements Searchable, NullableDeep<IClientBadgeViewModel> {
  public verificationType: AgreementType = AgreementType.SMS;
  public smsCode: Nullable<string> = null;
  public checkedBankSecrecySentEmailApprove: boolean = false;

  constructor(clientDTO?: ClientDTO) {
    super(clientDTO);
    if (clientDTO) {
      if (clientDTO.clientDataJson) {
        clientDTO.clientDataJson = new PersonalDetails(clientDTO.clientDataJson);
      } else {
        this.clientDataJson = new PersonalDetails();
      }
      if (clientDTO.mainAddress) {
        clientDTO.mainAddress = new Address(clientDTO.mainAddress);
      }
      if (clientDTO.primaryAddress) {
        clientDTO.primaryAddress = new Address(clientDTO.primaryAddress);
      }
      if (clientDTO.secondaryAddress) {
        clientDTO.secondaryAddress = new Address(clientDTO.secondaryAddress);
      }
      if (clientDTO.personalIds) {
        clientDTO.personalIds = clientDTO.personalIds.map(h => new PersonalId(0, h));
      }
      if (clientDTO.owner) {
        clientDTO.owner = new User(clientDTO.owner);
      }
      Utils.assign(this, clientDTO);
      this.setMultiformDefaults();
    }
  }

  // TODO ROMANIA
  private setMultiformDefaults() {
    if (this.pesel && this.clientDataJson) {
      const config: { [Gender.MALE]: number, [Gender.FEMALE]: number } =
        EnvironmentService.Environment.getAppDomainConfig().retirementAge;
      if (!this.clientDataJson.plannedRetirementAge) {
        this.clientDataJson.plannedRetirementAge = this.isMale ? config[Gender.MALE] : config[Gender.FEMALE];
      }
    }
  }

  public get userIsOwner(): boolean {
    return this.ownerId === AuthService.User?.id;
  }

  public get displayAddress(): string {
    return addressFormat(this.mainAddress);
  }

  public get textToSearch(): string {
    return [
      this.displayAddress,
      this.displayName,
      this.pesel,
      this.email,
      this.phone,
    ].join('');
  }

  public get birthDay(): Nullable<Date> {
    if (this.pesel) {
      return EnvironmentService.Environment.getAppDomainConfig()
        .personalIdNumber.utils.birthDay(this.pesel);
    } else {
      return null;
    }
  }

  public get companyObject() {
    return Utils.pick(this, ...companyObjectPickKeys);
  }

  public get clientObject() {
    return Utils.pick(this, ...clientObjectPickKeys);
  }

  public get clientDocuments(): ClientScan[] {
    return Vue.observable(ClientScans.filter(doc => !doc.condition || doc.condition(this)));
  };

  public get isEditableByClientIcon(): string {
    return this.isEditableByClient ? 'mdi-lock-open-variant' : 'mdi-lock';
  }

  public get mainAccount(): Nullable<Account> {
    return this.clientDataJson?.accounts[0] || null;
  }

  public get age(): Nullable<number> {
    return this.birthDay ? moment(new Date()).diff(this.birthDay, 'year', false) || 0 : null;
  }

  get filledPercentage(): number {
    return Utils.getPercentageFilled(this.dataForCheckingFilledPercentage);
  }

  get isUserOwner(): boolean {
    return !!AuthService.User &&
      (AuthService.User.isAdminOrVerifier ||
        AuthService.User.privileges?.isAgentCC ||
        (AuthService.User.isChiefOfDivision &&
          this.owner?.divisions?.some(id => AuthService.User!.divisionsChief?.some(item => item.id === id))) ||
        this.ownerId === AuthService.User.id ||
        this.delegatedTo?.id === AuthService.User.id);
  }

  get isUserDelegatee(): boolean {
    return this.isUserOwner &&
      !!this.delegatedTo?.id && this.delegatedTo?.id !== AuthService.User!.id;
  }

  get userHaveSimplePartnerView(): boolean {
    return AuthService.User!.privileges.simplePartnerView &&
      (!this.isUserOwner || !!this.delegatedTo?.id);
  }

  get userCanEdit(): Record<ClientDetailsSegments, boolean> {
    return {
      [ClientDetailsSegments.PREVIEW]: this.isUserOwner,
      [ClientDetailsSegments.DOCS]: this.isUserOwner || this.isUserDelegatee,
      [ClientDetailsSegments.ACTIVITY]: this.isUserOwner,
      [ClientDetailsSegments.BIK]: this.isUserOwner,
      [ClientDetailsSegments.DEALS]: this.isUserOwner || this.isUserDelegatee,
      [ClientDetailsSegments.NOTES]: !this.userHaveSimplePartnerView,
      [ClientDetailsSegments.MULTIFORM]: EnvironmentService.Environment.hasAccessToModule(CrmModules.MULTIFORM) &&
        this.isUserOwner,
      [ClientDetailsSegments.EKO]: this.isUserOwner,
      [ClientDetailsSegments.CLIENT_DATA]: this.isUserOwner,
      [ClientDetailsSegments.BIK_OLD]: !AuthService.User!.privileges.simplePartnerView &&
        AuthService.User!.isAppDomain.LENDI_PL,
    };
  }

  get userCanDisplay(): Record<ClientDetailsSegments, boolean> {
    return {
      [ClientDetailsSegments.PREVIEW]: true,
      [ClientDetailsSegments.DOCS]: this.isUserOwner,
      [ClientDetailsSegments.ACTIVITY]: this.isUserOwner && !AuthService.User!.privileges.simplePartnerView,
      [ClientDetailsSegments.BIK]: !AuthService.User!.privileges.simplePartnerView &&
        AuthService.User!.isAppDomain.LENDI_PL,
      [ClientDetailsSegments.DEALS]: this.isUserOwner || AuthService.User!.privileges.isAgentCC,
      [ClientDetailsSegments.NOTES]: true,
      [ClientDetailsSegments.MULTIFORM]: this.isUserOwner && !AuthService.User!.privileges.simplePartnerView,
      [ClientDetailsSegments.EKO]: !this.userHaveSimplePartnerView,
      [ClientDetailsSegments.CLIENT_DATA]: true,
      [ClientDetailsSegments.BIK_OLD]: !AuthService.User!.privileges.simplePartnerView &&
        AuthService.User!.isAppDomain.LENDI_PL,
    };
  }

  get dataForCheckingFilledPercentage(): PartialDeep<Client> {
    const clonedData: PartialDeep<Client> = clone(this);
    if (clonedData.isCompany) {
      delete clonedData.pesel;
    } else {
      delete clonedData.regon;
      delete clonedData.nip;
    }
    if (clonedData.primaryAddressAsMainAddress) {
      delete clonedData.primaryAddress;
    }
    if (clonedData.secondaryAddress) {
      delete clonedData.secondaryAddress;
    }
    if (clonedData.clientDataJson?.hasIncome === false) {
      delete clonedData.clientDataJson.incomes;
    }
    if (clonedData.clientDataJson?.hasAssets === false) {
      delete clonedData.clientDataJson.assets;
    }
    if (clonedData.clientDataJson?.hasAccount === false) {
      delete clonedData.clientDataJson.accounts;
    }
    if (clonedData.clientDataJson?.hasLiabilities === false) {
      delete clonedData.clientDataJson.liabilities;
    }
    if (clonedData.clientDataJson?.hasLimits === false) {
      delete clonedData.clientDataJson.limits;
    }
    return clonedData;
  }
}

export class ClientBankSecrecyDetails {
  public isAccepted: boolean = false;
  public isEnabled: boolean = false;
  public isExpired: boolean = false;
  public expireAt: Nullable<string> = null;
  public acceptedAt: Nullable<string> = null;
  public partner: Nullable<Partner> = null;
  public user: Nullable<User> = null;
}

export class ClientRemoveResponse {
  public failure: Array<number> = [];
  public success: Array<number> = [];
}
