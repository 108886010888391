import { IMultiformDictionary, IMultiformDictionaryElement } from '@/modules/multiForm/shared/IMultiformDictionary';
import { OfferDataTableElementKey } from '@/components/calculator/results/offers/OfferTableElement';
import { Features, Offer } from '@/models/simulation/Simulation';
import { SimulationProductType, useSimulationStore } from '@/components/calculator/services/SimulationStore';
import { ProductType } from '@/commons/enums/ProductType';
import { groupBy, map, minBy } from 'lodash-es';
import { I18NGetter } from '@/services/enumTranslator/I18NGetter';
import { useSimulationCurrency } from '@/components/calculator/useSimulationCurrency';
import AuthService from '@/modules/user/AuthService';
import { ReferenceRateType } from '@/models/simulation/ReferenceRateType';
import EnvironmentService from '@/env/EnvironmentService';

export enum FilterCategory {
  INTEREST_RATE = 'INTEREST_RATE',
  REFERENCE_RATE = 'REFERENCE_RATE',
  OFFER_FEATURES = 'OFFER_FEATURES',
  PROGRAM = 'PROGRAM',
}

interface ISortingMultiformElement<T> extends IMultiformDictionaryElement<T> {
  sortFunction: (a: Offer, b: Offer) => number
}
export interface OfferFilters extends IMultiformDictionaryElement<FilterKey> {
  filterFunction: (offer: Offer) => boolean,
  condition?: () => boolean,
}
export type IFilteringMultiformElement = {
  [K in FilterCategory]?: OfferFilters[]
}

export type FilterKey = `${keyof Features}True` | `${keyof Features}False` | string

export type OfferFilter = Record<FilterKey, boolean>

const useOfferSorting = () => {
  const store = useSimulationStore();
  const { isForeignCurrency, } = useSimulationCurrency();

  const offerFilters: Record<SimulationProductType, IFilteringMultiformElement> = {
    [ProductType.MORTGAGE]: {
      [FilterCategory.PROGRAM]: [
        {
          name_pl: I18NGetter().useOfferSorting.NORMAL_OFFERS,
          type: 'noProgramTrue',
          condition: () => Boolean(AuthService.User?.isAppDomain.LENDI_PL),
          filterFunction: (x: Offer) => !x.features.isSafeCredit2Percent && !x.features.isRkmProgram && !x.features.isHomeStartProgram,
        },
        {
          name_pl: I18NGetter().useOfferSorting.NORMAL_OFFERS,
          type: 'noProgramTrue',
          condition: () => Boolean(AuthService.User?.isAppDomain.NESTO_RO),
          filterFunction: (x: Offer) => !x.features.isNewHomeRO,
        },
        {
          name_pl: I18NGetter().useOfferSorting.HOME_START_OFFERS,
          type: 'isHomeStartProgramTrue',
          // TODO odblokowac po dodaniu ofert
          condition: () => Boolean(AuthService.User?.isAppDomain.LENDI_PL) && false,
          filterFunction: (x: Offer) => !!x.features.isHomeStartProgram,
        },
        {
          name_pl: I18NGetter().useOfferSorting.RKM_OFFERS,
          type: 'isRkmTrue',
          filterFunction: (x: Offer) => !!x.features.isRkmProgram,
          condition: () => Boolean(AuthService.User?.isAppDomain.LENDI_PL),
        },
        {
          name_pl: 'Noua Casă',
          type: 'isNewHomeROTrue', // Ta sama flaga co wyżej, wykorzystywana u rumunów, jako flaga rządowych ofert
          filterFunction: (x: Offer) => !!x.features.isNewHomeRO,
          condition: () => Boolean(AuthService.User?.isAppDomain.NESTO_RO),
        },
      ],
      [FilterCategory.OFFER_FEATURES]: [
        {
          name_pl: I18NGetter().useOfferSorting.ONLY_EKO_OFFERS,
          type: 'notEKOFalse',
          filterFunction: (x: Offer) => !x.features.notEKO,
        },
        {
          name_pl: I18NGetter().useOfferSorting.HIDE_EKO_OFFERS,
          type: 'notEKOTrue',
          filterFunction: (x: Offer) => x.features.notEKO,
        },
        {
          name_pl: I18NGetter().useOfferSorting.X_SELL_OFFERS,
          type: 'xSellTrue',
          filterFunction: (x: Offer) => !x.features.noXsell,
        },
        {
          name_pl: I18NGetter().useOfferSorting.PROFITABLE_EARLIER_PAYMENT,
          type: 'profitableEarlierPaymentTrue',
          filterFunction: (x: Offer) => x.features.profitableEarlierPayment,
        },
        {
          name_pl: I18NGetter().useOfferSorting.CHEAPEST_BANK_OFFERS,
          type: 'onlyCheapestBankOfferTrue',
          filterFunction: (x: Offer) => {
            const offersGroupedByBanks = groupBy(store.simulation?.offers, 'info.bank');
            const cheapestBankOffers = map(offersGroupedByBanks, (group: Offer[]) => minBy(group, 'totals.costsSum'));
            return cheapestBankOffers.map(o => o?.info.id).includes(x.info.id);
          },
        },
      ],
      [FilterCategory.INTEREST_RATE]: [
        {
          name_pl: I18NGetter().useOfferSorting.CONST_INTEREST,
          type: 'variableInterestFalse',
          filterFunction: (x: Offer) => !x.features.variableInterest,
        },
        {
          name_pl: I18NGetter().useOfferSorting.VARIABLE_INTEREST,
          type: 'variableInterestTrue',
          filterFunction: (x: Offer) => x.features.variableInterest,
        },
      ],
      [FilterCategory.REFERENCE_RATE]: [
        {
          name_pl: I18NGetter().useOfferSorting.ONE_MONTH_REFERENCE_RATE,
          type: 'onlyOneMonthWironReferenceRateTrue',
          filterFunction: (x: Offer) => x.interestRate.referenceRateType === ReferenceRateType.WIBOR_1M,
          condition: () => Boolean(AuthService.User?.isAppDomain.LENDI_PL) && !isForeignCurrency(store.userInput),
        },
        {
          name_pl: I18NGetter().useOfferSorting.THREE_MONTH_REFERENCE_RATE,
          type: 'onlyThreeMonthReferenceRateTrue',
          filterFunction: (x: Offer) => x.interestRate.referenceRateType === ReferenceRateType.WIBOR_3M,
          condition: () => Boolean(AuthService.User?.isAppDomain.LENDI_PL) && !isForeignCurrency(store.userInput),
        },
        {
          name_pl: I18NGetter().useOfferSorting.SIX_MONTH_REFERENCE_RATE,
          type: 'onlySixMonthReferenceRateTrue',
          filterFunction: (x: Offer) => x.interestRate.referenceRateType === ReferenceRateType.WIBOR_6M,
          condition: () => Boolean(AuthService.User?.isAppDomain.LENDI_PL) && !isForeignCurrency(store.userInput),
        },
      ],
    },
    [ProductType.CASH]: {
      [FilterCategory.OFFER_FEATURES]: [
        {
          name_pl: I18NGetter().useOfferSorting.ONLY_EKO_OFFERS,
          type: 'notEKOFalse',
          filterFunction: (x: Offer) => !x.features.notEKO,
        },
        {
          name_pl: I18NGetter().useOfferSorting.HIDE_EKO_OFFERS,
          type: 'notEKOTrue',
          filterFunction: (x: Offer) => x.features.notEKO,
        },
        {
          name_pl: I18NGetter().useOfferSorting.FOREIGNERS,
          type: 'forForeignersTrue',
          filterFunction: (x: Offer) => x.features.forForeigners,
          condition: () => isExcludedFilter('forForeignersTrue'),
        },
        {
          name_pl: I18NGetter().useOfferSorting.CREDIT_HOLIDAYS,
          type: 'creditHolidaysTrue',
          filterFunction: (x: Offer) => x.features.creditHolidays,
          condition: () => isExcludedFilter('creditHolidaysTrue'),
        },
        {
          name_pl: I18NGetter().useOfferSorting.OVERNIGHT_SEPERATION,
          type: 'overnightSeparationTrue',
          filterFunction: (x: Offer) => x.features.overnightSeparation,
          condition: () => isExcludedFilter('overnightSeparationTrue'),
        },
        {
          name_pl: I18NGetter().useOfferSorting.NEGOTIATE_TERMS,
          type: 'negotiableTrue',
          filterFunction: (x: Offer) => x.features.negotiable,
          condition: () => isExcludedFilter('negotiableTrue'),
        },
        {
          name_pl: I18NGetter().useOfferSorting.JOB_BREAK_ACCEPTED,
          type: 'jobBreakAcceptedTrue',
          filterFunction: (x: Offer) => x.features.jobBreakAccepted,
          condition: () => isExcludedFilter('jobBreakAcceptedTrue'),
        },
        {
          name_pl: I18NGetter().useOfferSorting.ONLY_REMOTE_PROCESS_OFFERS,
          type: 'remoteProcessTrue',
          filterFunction: (x: Offer) => !!x.features.remoteProcess,
          condition: () => isExcludedFilter('remoteProcessTrue'),
        },
      ],
      [FilterCategory.INTEREST_RATE]: [
        {
          name_pl: I18NGetter().useOfferSorting.CONST_INTEREST,
          type: 'fixedInterestTrue',
          filterFunction: (offer: Offer) => offer.features.fixedInterest,
        },
        {
          name_pl: I18NGetter().useOfferSorting.VARIABLE_INTEREST,
          type: 'variableInterestTrue',
          filterFunction: (offer: Offer) => offer.features.variableInterest,
        },
      ],
    },
  }
  const isExcludedFilter = (key: FilterKey) => {
    return !EnvironmentService.Environment.getAppDomainConfig().cashComparisonHiddenFilters.includes(key);
  }

  const sortingVariantItems: IMultiformDictionary<boolean> = [
    { type: true, name_pl: I18NGetter().useOfferSorting.ASCENDING, },
    { type: false, name_pl: I18NGetter().useOfferSorting.DESCENDING, },
  ];

  const defaultSort: (a: Offer, b: Offer) => number = (a, b) => store.userInput?.isAscending
    ? a.totals.totalSum - b.totals.totalSum
    : b.totals.totalSum - a.totals.totalSum;

  const getComparableMargin = (offer: Offer): number => {
    return offer.changingMargin?.initialValue ?? offer.interestRate.margin
  }

  const commonSortItems: ISortingMultiformElement<OfferDataTableElementKey>[] = [
    {
      type: OfferDataTableElementKey.TOTAL_SUM,
      name_pl: I18NGetter().useOfferSorting.TOTAL_SUM,
      sortFunction: defaultSort,
    },
    {
      type: OfferDataTableElementKey.RRSO,
      name_pl: I18NGetter().useOfferSorting.RRSO,
      sortFunction: (a, b) => store.userInput?.isAscending
        ? a.rrso - b.rrso
        : b.rrso - a.rrso,
    },
    {
      type: OfferDataTableElementKey.INITIAL_COST,
      name_pl: I18NGetter().useOfferSorting.INITIAL_COSTS,
      sortFunction: (a, b) => store.userInput?.isAscending
        ? a.overview.initialCost - b.overview.initialCost
        : b.overview.initialCost - a.overview.initialCost,
    },
  ];

  const sortItems: Record<SimulationProductType, ISortingMultiformElement<OfferDataTableElementKey>[]> = {
    [ProductType.MORTGAGE]: [
      ...commonSortItems,
      {
        type: OfferDataTableElementKey.FIRST_INSTALLMENT,
        name_pl: I18NGetter().useOfferSorting.FIRST_INSTALLMENT,
        sortFunction: (a, b) => store.userInput?.isAscending
          ? a.overview.firstInstallment - b.overview.firstInstallment
          : b.overview.firstInstallment - a.overview.firstInstallment,
      },
      {
        type: OfferDataTableElementKey.LAST_INSTALLMENT,
        name_pl: I18NGetter().useOfferSorting.LAST_INSTALLMENT,
        sortFunction: (a, b) => store.userInput?.isAscending
          ? a.overview.lastInstallment - b.overview.lastInstallment
          : b.overview.lastInstallment - a.overview.lastInstallment,
      },
      {
        type: OfferDataTableElementKey.MARGIN,
        name_pl: I18NGetter().useOfferSorting.BANK_MARGIN,
        sortFunction: (a, b) => store.userInput?.isAscending
          ? getComparableMargin(a) - getComparableMargin(b)
          : getComparableMargin(b) - getComparableMargin(a),
      },
    ],
    [ProductType.CASH]: [
      ...commonSortItems,
      {
        type: OfferDataTableElementKey.FIRST_INSTALLMENT,
        name_pl: I18NGetter().useOfferSorting.RATE,
        sortFunction: (a, b) => store.userInput?.isAscending
          ? a.overview.firstInstallment - b.overview.firstInstallment
          : b.overview.firstInstallment - a.overview.firstInstallment,
      },
    ],
  };

  const availableProductsItems: IMultiformDictionaryElement<boolean>[] = [
    {
      name_pl: I18NGetter().useOfferSorting.AVAILABLE,
      type: true,
    },
    {
      name_pl: I18NGetter().useOfferSorting.EXCLUDED,
      type: false,
    },
  ];

  const insuranceItems: IMultiformDictionaryElement<string>[] = [
    {
      name_pl: I18NGetter().useOfferSorting.REAL_ESTATE_INSURANCE,
      type: 'realEstateInsurance',
    },
    {
      name_pl: I18NGetter().useOfferSorting.LIFE_INSURANCE,
      type: 'lifeInsurance',
    },
    {
      name_pl: I18NGetter().useOfferSorting.PAYMENT_INSURANCE,
      type: 'paymentInsurance',
    },
  ];

  const extraProductItems: IMultiformDictionaryElement<string>[] = [
    {
      name_pl: I18NGetter().useOfferSorting.ACQUIRE_CREDIT_CARD,
      type: 'acquireCreditCard',
    },
  ];

  const filterVisibilityPredicate = (filter: OfferFilters): boolean => {
    return !filter.condition || filter.condition()
  }

  const filterWithPredicate = (predicate: (...args) => boolean): (filters: OfferFilters[] | undefined) => (OfferFilters[] | []) => {
    return (filters: OfferFilters[] | undefined) => {
      return filters?.filter(predicate) || []
    }
  }

  const getVisibleFilters = filterWithPredicate(filterVisibilityPredicate)

  const getFiltersByCategory = (category: FilterCategory): OfferFilters[] => {
    return offerFilters[store.productType!][category] || []
  }

  const getVisibleFiltersByCategory = (category: FilterCategory): OfferFilters[] => {
    return getVisibleFilters(getFiltersByCategory(category))
  }

  return {
    sortItems,
    defaultSort,
    sortingVariantItems,
    offerFilters,
    availableProductsItems,
    insuranceItems,
    extraProductItems,
    getVisibleFiltersByCategory,
  };
};

export default useOfferSorting;
