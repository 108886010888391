<template>
  <v-row class="ma-0 column-content"
         :class="[...selectedOfferClassList]">
    <v-col class="pt-0 pa-0">
      <v-row class="institution-badge-wrapper bb flex-column"
             :class="[
               $onboarding.tours.MORTGAGE_CALCULATOR.step.OFFERS_MANAGEMENT,
               {'short-name': store.userInput.hideOfferName && store.isShownAvailableProducts},
               ]"
             @click="store.isShownAvailableProducts && (isOfferSelected = !isOfferSelected)">
        <v-col
          class="text-center p-relative "
          :class="{'pb-1': store.isShownAvailableProducts || !store.userInput.hideOfferName}">
          <template v-if="!store.userInput.hideOfferName">
            <bank-logo :bank-symbol="offer.info.bank"/>
          </template>
          <template v-else>
            <p class="font-weight-medium">{{offer.shortName}}</p>
          </template>
          <div class="actions">
            <div class="left-actions d-inline-flex p-absolute">
              <v-checkbox
                  v-if="store.isShownAvailableProducts"
                  :input-value="isOfferSelected"
                  class="action-checkbox"/>
              <v-btn v-if="$env.isPreprodOrDev" icon @click.stop="copyOffer()">
                <v-icon>mdi-dev-to</v-icon>
              </v-btn>
              <div class="mt-1">
                <reference-rate-icon class="mt-1" :reference-rate="offer.interestRate.referenceRateType" />
              </div>
            </div>
            <div v-if="store.isShownAvailableProducts" class="right-actions d-inline-flex p-absolute">
              <v-tooltip left>
                <template #activator="{on}">
                  <v-btn
                      v-on="on"
                      icon text
                      class="mr-1"
                      :disabled="store.loading"
                      @click.stop="offer.info.isVariant ? removeDuplication() : duplicate()">
                    <v-icon color="error" v-if="offer.info.isVariant">mdi-trash-can-outline</v-icon>
                    <v-icon color="primary" v-else>mdi-plus</v-icon>
                  </v-btn>
                </template>
                {{ offer.info.isVariant ? I8NGetter().useOfferDetails.DELETE_DUPLICATE : I8NGetter().useOfferDetails.DUPLICATE }}
              </v-tooltip>
              <v-checkbox
                  @click.stop
                  v-model="isOfferPinned"
                  off-icon="mdi-pin-outline"
                  class="action-checkbox"
                  on-icon="mdi-pin-off">

              </v-checkbox>
            </div>
          </div>
        </v-col>
        <v-row v-if="!store.userInput.hideOfferName">
          <v-col class="pt-0">
            <p class="mb-0 font-weight-medium text-center">{{ offer.info.bankName }}</p>
            <div class="offer-title-wrapper">
              <p class="offer-title px-4 mb-0 caption text-wrap text--secondary text-center">
                {{ offerTitle }}
              </p>
            </div>
          </v-col>
        </v-row>
      </v-row>
      <v-row class="offer-tables">
        <v-col class="py-0">
          <v-list color="transparent" dense class="pb-0 pt-0">
            <template v-for="(dataElement, index) in dataTableElements">
              <div class="v-list-group"
                   :key="dataElement.key + offer.info.id"
              >
                <v-list-item
                      :style="listElementHeight(dataElement, index ? 1 : 0)"
                      class="justify-center pa-2"
                      @mouseover="dataElement.hover = true"
                      @mouseleave="dataElement.hover = false"
                      :class="[dataElement.class, {'bt': index}]">
                      <v-skeleton-loader
                          type="text"
                          :width="loaderWidth(dataElement)"
                          v-if="store.loading"/>
                      <template v-else-if="scope">
                        <v-runtime-template
                            v-if="dataElement.runtimeTemplate"
                            :templateProps="scope"
                            :template="createTemplate(dataElement)"/>
                        <div v-else v-html-safe="createTemplate(dataElement)"></div>
                      </template>
                  </v-list-item>
                  <div v-if="dataElement.open" :key="dataElement.key">
                    <div
                        v-for="(child, i) in dataElement.children"
                        :class="child.class"
                        :style="listElementHeight(child)"
                        :key="i"
                        class="bt display-flex align-center justify-center text-wrap overflow-y-auto body-2 pa-2">
                      <template v-if="scope">
                          <v-runtime-template
                              v-if="child.runtimeTemplate"
                              :templateProps="scope"
                              :template="createTemplate(child)"/>
                          <div v-else v-html-safe="createTemplate(child)"></div>
                      </template>
                    </div>
                  </div>
              </div>
            </template>
            <div class="border-bottom"></div>
          </v-list>
          <v-divider/>
          <slot></slot>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>
<script lang="ts">
/* eslint-disable vue/no-unused-components */

import {Offer} from '@/models/simulation/Simulation';
import InstitutionBadge from '@/components/badges/InstitutionBadge.vue';
import BankLogo from '@/components/calculator/BankLogo.vue';
import {
  OfferDataTableElementKey,
  OfferTableElement,
  OfferTableViewElement
} from '@/components/calculator/results/offers/OfferTableElement';
import {computed, defineComponent, getCurrentInstance, PropType, ref} from 'vue';
import {useSimulationStore} from '@/components/calculator/services/SimulationStore';
import {Utils} from '@/services/utils/BasicUtils';
import VRuntimeTemplate from 'v-runtime-template';
import {useOffersTable} from '@/components/calculator/results/offers/useOffersTable';
import XSellVariantsRadio from '@/components/commons/calculator/XSellVariantsRadio.vue';
import {getByType} from '@/modules/multiForm/shared/MultiformDictionaries';
import {LoanPurposeWhat} from '@/models/simulation/LoanPurposeWhat';
import ScheduleModal from '@/components/calculator/schedule/ScheduleModal.vue';
import EnvironmentProducts from '@/env/services/EnvironmentProducts';
import {useOfferReplacements} from '@/components/calculator/results/useOfferReplacements';
import {I18NGetter} from '@/services/enumTranslator/I18NGetter';
import {ReferenceRateType} from '@/models/simulation/ReferenceRateType';
import {FirstOrSubsequentRealEstate} from '@/models/simulation/FirstOrSubsequentRealEstate';
import {IncomeType, Profession} from '@/modules/multiForm/shared/Types';
import ReferenceRateIcon from '@/components/calculator/results/offers/ReferenceRateIcon.vue';
import {numeralFormat} from '@/filters/StringFilters';

export default defineComponent({
  components: {
    ReferenceRateIcon,
    InstitutionBadge,
    VRuntimeTemplate,
    XSellVariantsRadio,
    BankLogo,
  },
  props: {
    offer: {
      type: Object as PropType<Offer>,
      required: true,
    },
    loading: {
      type: Boolean as PropType<boolean>,
      required: false,
    },
    dataTableElements: {
      type: Array as PropType<OfferTableElement[]>,
      required: true,
    },
  },
  setup(props, context) {
    const store = useSimulationStore();
    const {
      onEventualMarginChange,
      onMarginChange,
      onCustomAmountChange,
      onCustomPeriodChange,
      manageCreditCardDemand,
      onProvisionPercentageChange,
      onBrokerCommissionChange,
    } = useOfferReplacements(props.offer);
    const instance = getCurrentInstance();

    const focusTempValue = ref<Nullable<number>>(null);

    const onFocus = (value: number | null) => {
        focusTempValue.value = value;
    }

    const onEnterHandler = (_event: Event, model: number | null, callback: (value: number | null) => void) => {
        if (model !== null && focusTempValue.value !== model) {
          callback(model);
          focusTempValue.value = model;
        }
    }

    const onBlurHandler = (currentValue: number | null, callback: (value: number | null) => void) => {
        if (focusTempValue.value !== currentValue && currentValue !== null) {
            callback(currentValue);
        }
        focusTempValue.value = null;
    };

    const I8NGetter = I18NGetter;

    const duplicate = async() => {
      try {
        await store.duplicateOffer(props.offer.info.id);
      } catch (e) {
        instance?.proxy.errorHandler(e, I18NGetter().useOfferDetails.DUPLICATE_ERROR);
      }
    };

    const isOfferPinned = computed<boolean>({
      get: () => !!(store.userInput && store.userInput.pinnedOffers.includes(props.offer.info.id)),
      set: (value: boolean) => value ? store.pinOffer(props.offer.info.id) : store.unpinOffer(props.offer.info.id),
    });

    const isOfferSelected = computed<boolean>({
      get: () => !!(store.userInput && store.userInput.selectedOffers.includes(props.offer.info.id)),
      set: (value: boolean) => value
        ? store.selectOffer(props.offer.info.id) : store.unselectOffer(props.offer.info.id),
    });
    const removeDuplication = async() => {
      try {
        await store.removeDuplication(props.offer.info.id);
      } catch (e) {
        instance?.proxy.errorHandler(e, I18NGetter().useOfferDetails.REMOVE_DUPLICATE_ERROR);
      }
    };

    const customLoanAmount = computed(() =>
      props.offer.customizations?.customLoanAmount || store.userInput?.loanAmount || 0);

    const customLoanPeriod = computed(() =>
      props.offer.customizations?.customLoanPeriodInMonths ||
      store.userInput?.loanPeriodInMonths || store.userInput?.loanPeriod || 0);

    const offerTitle = computed<string>(() =>
      props.offer.info.title ||
      `${I18NGetter().useOfferDetails.OFFER_TITLE} ${EnvironmentProducts().find(x => x.type === store.productType!)?.nominative?.male}`
    );

    const loaderWidth = (dataElement: OfferTableElement) => dataElement.render
      ? Utils.charWidth * dataElement.render(props.offer).length
      : '80%';

    const createTemplate: (element: OfferTableViewElement) => string = (element: OfferTableViewElement) => {
      return `<div>${element.render ? element.render(props.offer, store.userInput) : ''}</div>`;
    };

    function getSelectedOfferClassList(): string[] {
      if (!isOfferSelected.value) {
        return [];
      }
      const arrayContainingThisOffer: Offer[] = isOfferPinned.value ? store.pinnedOffers : store.sortedOffers;
      const offerIndex: number = arrayContainingThisOffer
        .findIndex((offer: Offer) => offer.info.id === props.offer.info.id);

      const isPreviousOfferSelected: boolean = offerIndex > 0 &&
        !!store.userInput?.selectedOffers.includes(arrayContainingThisOffer[offerIndex - 1].info.id);
      const isNextOfferSelected: boolean = offerIndex < arrayContainingThisOffer.length - 1 &&
        !!store.userInput?.selectedOffers.includes(arrayContainingThisOffer[offerIndex + 1].info.id);

      const borderLeft: string = isPreviousOfferSelected ? '' : 'border-left';
      const borderRight: string = isNextOfferSelected ? '' : 'border-right';
      return ['selected', borderLeft, borderRight,];
    }

    const selectedOfferClassList = computed<string[]>(() => getSelectedOfferClassList());

    const getProfessionName = (type: Profession): string => getByType('professionDictionary', type);
    const getIncomeTypeName = (type: IncomeType): string => getByType('incomeTypesDictionary', type);
    const getCollateralName = (type: LoanPurposeWhat): string => getByType('fFCollaterals', type);
    const getFirstOrSubsequentRealEstateName = (type: FirstOrSubsequentRealEstate): string => getByType('firstOrSubsequentRealEstateDictionary', type);

    const openScheduleModal = () => {
      instance?.proxy.$modalService.open(ScheduleModal, {offer: props.offer, userInput: store.userInput,},
        {maxWidth: props.offer.features.isHomeStartProgram ? 1200 : 1050, persistent: true, fullscreen: undefined, });
    };

    const componentScope = {
      store,
      removeDuplication,
      duplicate,
      OfferDataTableElementKey,
      customLoanAmount,
      customLoanPeriod,
      onCustomAmountChange,
      onCustomPeriodChange,
      loaderWidth,
      createTemplate,
      onMarginChange,
      onEventualMarginChange,
      onProvisionPercentageChange,
      onBrokerCommissionChange,
      manageCreditCardDemand,
      copyOffer: () => instance?.proxy.$clipboard(JSON.stringify(props.offer)),
      getProfessionName,
      getIncomeTypeName,
      getCollateralName,
      getFirstOrSubsequentRealEstateName,
      selectedOfferClassList,
      isOfferSelected,
      isOfferPinned,
      print: false,
      openScheduleModal,
      offerTitle,
      I8NGetter,
      ReferenceRateType,
      onBlurHandler,
      onEnterHandler,
      onFocus,
    };

    const {scope, listElementHeight,} = useOffersTable(props, context, componentScope);
    return {
      ...componentScope,
      listElementHeight,
      scope,
    };
  },
});

</script>
<style scoped lang="scss">
.offer-title-wrapper {
  overflow: hidden;
}
.offer-title {
  height: 48px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.institution-badge-wrapper:hover .offer-title {
  overflow-y: auto;
  display: block;
}

::v-deep .xsell-switch {
  margin-top: 0px;
  margin-bottom: 8px;

  .v-input__slot {
    margin-bottom: 0px;
  }

  label.v-label {
    color: var(--v-primary-base);
  }
}

.actions {
  .right-actions {
    position: absolute;
    top: 2px;
    right: 8px;
  }

  .left-actions {
    position: absolute;
    top: 2px;
    left: 16px;
  }
}

::v-deep .insurances {
  .fp-radio-group {
    padding-top: 0px;

    label.v-label {
      font-size: 14px;
    }
  }
}

::v-deep .percent-input {
  max-width: 80px;
}

::v-deep .percent-input-long {
  max-width: 104px;
}

::v-deep .loan-period-input {
  max-width: 160px;
}

::v-deep .loan-amount-input {
  max-width: 160px;
}

@media screen {
  .institution-badge-wrapper {
    position: sticky;
    top: 0;
    z-index: 8;
    margin: 0;
    padding: 0 2px;
    cursor: pointer;

    &.short-name {
      height: 81px;
    }

    p {
      margin: 0;
    }

    &__border {
      position: sticky;
      top: 60px;
      z-index: 8;

      &.short-name {
        top: 80px;
      }
    }
  }
}

.action-checkbox {
  margin-top: 0px;
  padding-top: 6px;
}

.institution-badge-wrapper:hover {
  ::v-deep .mdi-checkbox-blank-outline::before {
    color: $primary;
  }
}

::v-deep .v-lazy {
  padding: 0 2px;
}

.column-content {
  &.selected {
    padding: 0;
    $selected-offer-border: 2px solid $primary;

    &.border-left {
      border-left: $selected-offer-border;
    }

    &.border-right {
      border-right: $selected-offer-border;
    }

    .institution-badge-wrapper {
      padding: 0;
      border-top: $selected-offer-border !important;
    }

    .border-bottom {
      position: sticky;
      bottom: 0;
      border-bottom: $selected-offer-border;

      @media screen and (max-width: 600px) {
        display: none;
      }
    }

    ::v-deep .v-lazy {
      padding: 0;
    }
  }
}
</style>
