<template>
  <simulation-results
      :simulationResultConfig="simulationResultConfig">
    <template #user-panel-credit-parameters-extension>
      <v-form ref="USER_INPUT_FORM">
      <fp-input
          dense
          :suffix="$env.currentCurrency()"
          prepend-icon="mdi-cash-fast"
          type="number"
          int
          required
          @focus="onFocus"
          @onEnter="(event, value) => onEnter(event, value, 'loanAmount')"
          @blur="(value) => onBlur(value, 'loanAmount')"
          :value="store.userInput.loanAmount"></fp-input>
      <fp-input
          dense
          :label="I18NGetter().useCashSimulationResultsAndFormStep1.LOAN_PERIOD"
          :suffix="I18NGetter().useCashSimulationResultsAndFormStep1.MONTHS"
          prepend-icon="mdi-clock-outline"
          :value="store.userInput.loanPeriod"
          @focus="onFocus"
          @onEnter="(event, value) => onEnter(event, value, 'loanPeriod')"
          @blur="(value) => onBlur(value, 'loanPeriod')"
          int
          required
          type="number"/>
          <div v-if="$env.isAppDomain().NESTO_RO" class="d-flex align-center pl-2 w-100">
        <span class="body-2 text--secondary font-weight-medium">DTI</span>
        <fp-input
            dense
            type="number"
            suffix="%"
            int
            required
            :custom-rules="debtToIncomeRules"
            @focus="onFocus"
            @onEnter="(event, value) => onEnter(event, value, 'acceptableDtiPercentage')"
            @blur="(value) => onBlur(value, 'acceptableDtiPercentage')"
            :value="store.userInput.acceptableDtiPercentage"
        />
      </div>
      <v-flex>
        <span class="font-weight-medium">{{ I18NGetter().useCashSimulationResultsAndFormStep1.LOAN_TYPES }}</span>
        <v-layout wrap>
          <v-flex xs12>
            <fp-checkbox
                v-model="store.userInput.loanTypes.cash"
                :label="I18NGetter().useCashSimulationResultsAndFormStep1.CASH"/>
          </v-flex>
          <v-flex xs12 wrap>
            <fp-checkbox
                v-model="store.userInput.loanTypes.consolidation"
                :label="I18NGetter().useCashSimulationResultsAndFormStep1.CONSOLIDATION"/>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-form>
    </template>
  </simulation-results>
</template>
<script lang="ts">
import {ProductType} from '@/commons/enums/ProductType';
import {SimulationResultConfig} from '@/components/calculator/results/SimulationResultConfig';
import {OfferDataTableElementKey} from '@/components/calculator/results/offers/OfferTableElement';
import {numeralFormat} from '@/filters/StringFilters';
import {Offer} from '@/models/simulation/Simulation';
import {defineComponent, getCurrentInstance, onMounted, Ref, ref} from 'vue';
import {useSimulationStore} from '@/components/calculator/services/SimulationStore';
import {UserInputPanelElementEnum} from '@/components/calculator/results/userInputPanel/UserInputPanelElementEnum';
import SimulationResults from '@/components/calculator/results/SimulationResults.vue';
import {CashTableElements} from '@/components/calculator/results/tableElements/CashTableElements';
import {useDealForm} from '@/components/calculator/form/useSimulationForm';
import {I18NGetter} from '@/services/enumTranslator/I18NGetter';
import { useCalculatorFormRules } from '../useCalculatorFormRules';

type SimulationStoreFields = 'loanPeriod' | 'loanPeriodInMonths' | 'explicitBridgingPeriod' | 'acceptableDtiPercentage' | 'loanAmount' | 'hypothecValue' | 'currency';

export default defineComponent({
  components: {SimulationResults,},
  setup() {
    const instance = getCurrentInstance();
    const {debtToIncomeRules,} = useCalculatorFormRules();
    const simulationResultConfig: Ref<SimulationResultConfig> = ref({
      fullscreen: !!instance?.proxy.$vuetify.breakpoint.mdAndDown,
      userInputPanels: [] as UserInputPanelElementEnum[],
    });
    const store = useSimulationStore();
    const simulationId = instance?.proxy.$route.params.simulationId;
    store.init(ProductType.CASH, simulationId);
    const USER_INPUT_FORM = ref<HTMLFormElement | null>(null);
    const focusTempValue = ref<Nullable<number>>(null);
    const validateForm = () => {
      return USER_INPUT_FORM.value?.validate();
    }

    const onFocus = (value: number | null) => {
    focusTempValue.value = value;
    }

    const onEnter = (_event: Event, model: number | null, field: SimulationStoreFields) => {
        if (focusTempValue.value !== model && model !== null) {
        if (validateForm() && store.userInput) {
          store.userInput[field] = model;
          store.createOrUpdateSimulation();
          focusTempValue.value = model;
        }
      }
    }

  const onBlur = (currentValue: number | null, field: SimulationStoreFields) => {
    if (focusTempValue.value !== currentValue && currentValue !== null) {
      if (validateForm() && store.userInput) {
        store.userInput[field] = currentValue;
        store.createOrUpdateSimulation();
      }
    }
    focusTempValue.value = null;
  };


    return {
      simulationResultConfig,
      store,
      I18NGetter,
      debtToIncomeRules,
      USER_INPUT_FORM,
      onFocus,
      onEnter,
      onBlur
    };
  },
});
</script>
<style scoped lang="scss">
</style>
