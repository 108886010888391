import {I18NGetter} from '@/services/enumTranslator/I18NGetter';
export enum FilterType {
  TEXT = 'text',
  DATE = 'date',
  RANGE = 'range',
  NUMBER = 'number',
  AUTOCOMPLETE = 'autocomplete',
  RADIO = 'radio',
  RADIO_EXPERT_ATTRIBUTES = 'radio_expert_attributes',
  BOOLEAN = 'boolean',
  STORE_DICT = 'store_dict',
  ADDRESS = 'address',
  MULTIFORM_DICT = 'multiform_dict',
}

export enum AutocompleteFilterTypes {
  CLIENTS = 'clients',
  USERS = 'users',
  PARTNERS = 'partners',
  DIVISIONS = 'divisionIds',
  BANKS = 'bankIds',
  OWNER_ID = 'ownerId',
}

export const FilterGroupDictionary: {text: string, filterType: FilterType}[] = [
  {
    text: I18NGetter().useFilterType.DATES,
    filterType: FilterType.DATE,
  },
  {
    text: I18NGetter().useFilterType.CREDIT_DOCUMENTATION,
    filterType: FilterType.RADIO,
  },
  {
    text: I18NGetter().useFilterType.EXPERT_ATTRIBUTES,
    filterType: FilterType.RADIO_EXPERT_ATTRIBUTES,
  },
];
