import {Bank} from '@/modules/banks/models/Bank';
import Client from '@/models/Client';
import {LoanApplicationStep} from '@/models/LoanApplicationStep';
import {LoanApplicationStepEnum} from '@/modules/loanApplication/enums/LoanApplicationStepEnum';
import {Owner} from '@/models/Owner';
import {LoanApplicationCategory} from '@/modules/activities/enums/LoanApplicationCategory';
import {CreditDetails} from '@/modules/multiForm/shared/MultiformModels';
import Offer from '@/models/Offer';
import {ProcessType} from '@/commons/enums/ProcessType';
import {HistoryElement} from '@/models/HistoryElement';
import {BankSymbol} from '@/commons/dictionaries/BankSymbol';
import {BankDictionaryElement} from '@/modules/banks/models/BankDictionaryElement';
import {banksDictionary} from '@/commons/dictionaries/BanksDictionary';
import {RaiffeisenApplication,} from '@/models/integrations/RaiffeisenLoanApplication';
import { LoanPurposeWhat } from './simulation/LoanPurposeWhat';
import { LoanPurposeActivity } from './simulation/LoanPurposeActivity';

export abstract class LoanApplicationDTO<TCreditDetails = CreditDetails> {
  public id!: number;
  public name: string = '';
  public modifiedAt!: string;
  public createdAt!: string;
  public bankAgentEmail: string = '';
  public bankBranchId: number = 0;
  public bank: Bank = new Bank();
  public amount: number = 0;
  public step!: LoanApplicationStep;
  public categoryId?: LoanApplicationCategory = LoanApplicationCategory.MORTGAGE;
  public realisationDate: string = '';
  public owner?: Owner;
  public offer?: Offer;
  public provision?: number;
  public bankProvisionReplacement: number = 0;
  public history: Array<HistoryElement<any>> = [];
  public clients!: Array<Client>;
  public processType!: ProcessType;
  public creditDetails!: TCreditDetails;
  public verifierId: number | null = null;
  public verifierStartedAt?: string;
  public preDecision!: boolean;
  public isProductAvailable?: boolean;
  public productId?: string;
  public bankConsultantName?: string;
  public bankBranchName?: string;
  public productName?: string;
  public period?: number;
  public loanPurpose?: {
    what: LoanPurposeWhat,
    activity: LoanPurposeActivity,
  };
  public deal!: {
    name: string,
    id: number,
  };

  public raiffeisenLead?: RaiffeisenApplication;
}

export default class LoanApplication<TCreditDetails = CreditDetails> extends LoanApplicationDTO<TCreditDetails> {
  public selected?: boolean = true;
  public predecisionGranted?: boolean = false;
    
  get bankObject(): Nullable<BankDictionaryElement> {
    return banksDictionary(true).find(x => x.type === this.bank.symbol) ?? null;
  }

  constructor(loanApplicationDTO?: LoanApplicationDTO) {
    super();
    if (loanApplicationDTO) {
      if (loanApplicationDTO.owner) {
        loanApplicationDTO.owner = new Owner(loanApplicationDTO.owner);
      }

      if (loanApplicationDTO.raiffeisenLead) {
        loanApplicationDTO.raiffeisenLead = new RaiffeisenApplication(loanApplicationDTO.raiffeisenLead);
      }

      if (loanApplicationDTO.clients) {
        loanApplicationDTO.clients = loanApplicationDTO.clients.map((c: Client) => new Client(c));
      }

      if (loanApplicationDTO.bank) {
        loanApplicationDTO.bank = new Bank(loanApplicationDTO.bank);
        if (!loanApplicationDTO.creditDetails) {
          const creditDetailsClass: typeof CreditDetails =
            (loanApplicationDTO.bank.meta && loanApplicationDTO.bank.meta.creditDetailsClass)
              ? loanApplicationDTO.bank.meta.creditDetailsClass
              : CreditDetails;
          this.creditDetails = new creditDetailsClass(loanApplicationDTO) as any as TCreditDetails;
        }
      } else {
        loanApplicationDTO.bank = new Bank({id: 0, symbol: BankSymbol.other, name: 'Inny bank',});
        console.warn('Application with no bank!', loanApplicationDTO);
      }
      Object.assign(this, loanApplicationDTO);
    }
  }

  public get isMortgage(): boolean {
    return this.categoryId === LoanApplicationCategory.MORTGAGE;
  }

  public get isInVerification(): boolean {
    return this.step.id === LoanApplicationStepEnum.WaitingForVerification;
  }

  public get canManuallyChangeStatus(): boolean {
    return !this.isProcessIntegration;
  }

  public get canSkipMultiform(): boolean {
    return !this.isProcessIntegration;
  }

  public get isAccepted(): boolean {
    return this.step.id === LoanApplicationStepEnum.Accepted;
  }

  public get isRejected(): boolean {
    return this.step.id === LoanApplicationStepEnum.Rejected;
  }

  public get isReturnedForCorrection(): boolean {
    return this.step.id === LoanApplicationStepEnum.ReturnedForCorrection;
  }

  public get isProcessDepartment(): boolean {
    return this.processType === ProcessType.Department;
  }

  public get isProcessIntegration(): boolean {
    return this.processType === ProcessType.Integration;
  }

  public get isProcessCentral(): boolean {
    return this.processType === ProcessType.Central;
  }

  public get modifiedAtObject() {
    return new Date(this.modifiedAt);
  }

  public set modifiedAtObject(newDate: Date) {
    this.modifiedAt = newDate.toISOString();
  }

  public get realisationDateObject() {
    return new Date(this.realisationDate);
  }

  public set realisationDateObject(newDate: Date) {
    this.realisationDate = newDate.toISOString();
  }

  public get isLoanApplicationAvailable(): boolean {
    return this.isProductAvailable !== false ? true : this.isProductAvailable;
  }

  public get stepColor() {
    switch (this.step.id) {
    case LoanApplicationStepEnum.ClientResigned:
    case LoanApplicationStepEnum.DecisionNegative:
    case LoanApplicationStepEnum.AgreementConditionNotMet:
    case LoanApplicationStepEnum.Rejected:
      return 'error';
    case LoanApplicationStepEnum.Started:
    case LoanApplicationStepEnum.ProvisionWasPaid:
    case LoanApplicationStepEnum.Accepted:
    case LoanApplicationStepEnum.SalesReportedToFinancialInstitution:
    case LoanApplicationStepEnum.FoundsReceivedFromFinancialInstitution:
    case LoanApplicationStepEnum.StatementsSentToExpert:
      return 'success';
    default:
      return '';
    }
  }
}
