import { render, staticRenderFns } from "./FpChipRadio.vue?vue&type=template&id=058b180a&scoped=true"
import script from "./FpChipRadio.vue?vue&type=script&lang=ts"
export * from "./FpChipRadio.vue?vue&type=script&lang=ts"
import style0 from "./FpChipRadio.vue?vue&type=style&index=0&id=058b180a&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.11.1_babel-core@6.26.3_css-loader@6.11.0_underscore@1.13.6_vue-template-compiler@2.7.14_webpack@5.89.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "058b180a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/.pnpm/vuetify-loader@1.7.3_vue-template-compiler@2.7.14_vuetify@2.7.1_webpack@5.89.0/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VItem } from 'vuetify/lib/components/VItemGroup';
import { VItemGroup } from 'vuetify/lib/components/VItemGroup';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VChip,VCol,VIcon,VItem,VItemGroup,VRow})
